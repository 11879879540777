import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { useHistory } from 'react-router-dom';


function BusinessRow(props) {
  const { business } = props;
  const history = useHistory()

  return (
    <TableRow
      hover
      onClick={() => history.push(`/businesses/${business.business_id}`)}
    >
      <TableCell>{business.business_name}</TableCell>
      <TableCell>{business.contact_name}</TableCell>
      <TableCell>{business.contact_phone}</TableCell>
    </TableRow>
  );
}

export default BusinessRow;