import React, { Component } from 'react';
import { CircularProgress, Grid, Hidden, Typography, Tooltip, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Axios from 'axios';
import { connect } from 'react-redux';
import { setAppLoading, addRecent } from '../../actions';
import { set, formatISO } from 'date-fns';
import _ from 'lodash';
import SettingsIcon from '@material-ui/icons/Settings';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import RefreshIcon from '@material-ui/icons/Refresh';

import Preview from './Preview';
import VerticalSelector from './Selectors/Vertical';
import HorizontalSelector from './Selectors/Horizontal';
import Status from './Status';
import EditDialog from './Dialogues/EditSite';
import TimesegmentsDialog from './Dialogues/Timesegments';
import Download from './Downloads';
import Map from './Map';
import handleError from '../../utils/handleError';


const styles = theme => ({
  infoText: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 'auto'
  },
  settingButton: {
    padding: 6,
    float: 'right'
  }
});

class Site extends Component {
  constructor(props) {
    super(props);
    this.siteID = props.match.params.siteID;

    this.state = {
      site: null,
      images: [],
      selectedImages: [],
      loadingImages: false,
      previewIndex: null,
      compareImages: [],
      showCompare: false,
      showEditDialog: false,
      showTimesegmentsDialog: false,
      takingPhoto: false,
      fromDate: null
    }
  }

  componentDidMount() {
    this.load();
    this.props.setAppLoading(true);
  }

  load() {
    Axios.get(`/account/sites/${this.siteID}`)
      .then(res => {
        this.setState({
          site: res.data.data,
        });
        // Add to recents if logged in
        if(this.props.auth.loggedIn) this.props.addRecent(res.data.data.site_id);
      })
      .catch(err => {
        this.setState({ site: false });
        console.log(err);
      });

    // Get initial images
    this.setFromDate(new Date());
  }

  takePhoto() {
    this.setState({ takingPhoto: true });
    Axios.post(`/account/sites/${this.siteID}/take-photo`)
      .then(res => {
        this.load();
      })
      .catch(err => {
        alert(handleError(err));
      })
      .finally(() => {
        this.setState({ takingPhoto: false });
      });
  }

  getImages = (resetImages=false, resetSelection=true) => {
    if(!this.state.loadingImages) {
      this.setState({
        loadingImages: true
      });

      var offset = resetImages ? 0 : this.state.images.length;

      var fromDate = set(this.state.fromDate, { hours: 23, minutes: 59, seconds: 59 });

      let params = {
        offset,
        from_date: formatISO(fromDate),
        quantity: 20,
      };

      if(resetSelection) {
        this.setState({ selectedImages: [] });
      }

      Axios.get(`/account/sites/${this.siteID}/images`, {
        params
      })
        .then(res => {
          let { previewIndex } = this.state;
          var images = resetImages ? [] : this.state.images;
          var data = res.data.data.map(item => ({
            ...item,
            selected: false
          }));
          var newImages = images.slice().concat(data);
  
          this.setState({
            images: newImages,
            previewIndex: ((previewIndex === null && data.length > 0) ? 0 : previewIndex)
          });
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.props.setAppLoading(false);
          this.setState({
            loadingImages: false
          });
        });
    }
  }

  previewImage = (name) => {
    this.setState({
      previewIndex: name,
      showCompare: false
    });
  }

  toggleSelectImage = (image) => {
    var selectedImages = this.state.selectedImages.slice();
    var compareImages = this.state.compareImages.slice();

    const selected = selectedImages.indexOf(image) > -1;

    if(selected) {
      selectedImages.splice(selectedImages.indexOf(image), 1);
      compareImages.splice(compareImages.indexOf(image), 1);
    } else {
      selectedImages.push(image);
      compareImages.push(image);
    }

    if(compareImages.length > 2) {
      compareImages.splice(0, 1);
    }

    this.setState({ selectedImages, compareImages });
  }

  toggleCompareImages = (show=!this.state.showCompare) => {
    this.setState({ showCompare: show });
  }

  showEditDialog = (show=!this.state.showEditDialog) => {
    this.setState({
      showEditDialog: show
    });
  }

  showTimesegmentsDialog = (show=!this.state.showTimesegmentsDialog) => {
    this.setState({
      showTimesegmentsDialog: show
    });
  }

  handleDataChange = (key, val) => {
    return new Promise(resolve => {
      var data = _.cloneDeep(this.state.site);
      data[key] = val;
  
      this.setState({
        site: data
      }, resolve);
    });
  }

  setFromDate = (fromDate) => {
    this.setState({ fromDate }, () => {
      this.getImages(true, !this.state.showCompare);
    });
  }

  deleteSelectedImages = () => {
    const selectedImageIDs = this.state.selectedImages.map(image => image.image_id);

    if(this.state.selectedImages.length < 1) {
      alert("No images selected.");
    } else if(window.confirm(`Delete ${this.state.selectedImages.length} images?`)) {
      Axios.delete(`/account/sites/${this.siteID}/images`, {
          data: {
            images: selectedImageIDs
          }
        }).then(res => {
          this.getImages(true, true)
        }).catch(err => {
          console.log(err);
        });
    }
  }

  canView = (access=[]) => {
    var rank;
    if(this.props.auth.account === null) {
      // Public View
      rank = 4;
    } else {
      // Logged In View
      rank = this.props.auth.account.level_id;
    }
    return access.indexOf(rank) > -1;
  }

  view() {
    const { site, images, selectedImages, previewIndex, showCompare, compareImages, showEditDialog, showTimesegmentsDialog, fromDate } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <Grid container>
          {/* Menu Bar */}
          <Grid item container xs={8} sm={9} md={10}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" className={classes.infoText}>
                <b>Site:</b> {site.site_name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" className={classes.infoText}>
                <b>Address:</b> {site.address}
              </Typography>
            </Grid>
          </Grid>

          {/* Controls */}
          {this.canView([1,2,3]) && <Grid container item xs={4} sm={3} md={2} justify="flex-end">
            <Grid item>
              <Tooltip title="Site Setup">
                <IconButton
                  className={classes.settingButton}
                  onClick={() => this.showEditDialog(true)}
                >
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Timesegments">
                <IconButton
                  className={classes.settingButton}
                  onClick={() => this.showTimesegmentsDialog(true)}
                >
                  <ScheduleIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Take Photo">
                <IconButton
                  className={classes.settingButton}
                  onClick={() => this.takePhoto()}
                  disabled={this.state.takingPhoto}
                >
                  {this.state.takingPhoto ? <CircularProgress size={24} thickness={4} /> : <CameraAltIcon />}
                </IconButton>
              </Tooltip>
              <Tooltip title="Refresh">
                <IconButton
                  className={classes.settingButton}
                  onClick={() => this.load()}
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>}
        </Grid>


        {/* Dialogs */}
        <EditDialog
          open={showEditDialog}
          site={site}
          handleDataChange={this.handleDataChange}
          handleClose={() => this.showEditDialog(false)}
          canView={this.canView}
        />
        <TimesegmentsDialog
          open={showTimesegmentsDialog}
          site={site}
          handleDataChange={this.handleDataChange}
          handleClose={() => this.showTimesegmentsDialog(false)}
        />


        {/* Content */}
        <Grid container spacing={2}>
          <Hidden xsDown>
            <Grid item sm={3} md={2}>
              <VerticalSelector
                url={site.url}
                images={images}
                selectedImages={selectedImages}
                getImages={this.getImages}
                previewImage={this.previewImage}
                toggleSelectImage={this.toggleSelectImage}
                fromDate={fromDate}
                setFromDate={this.setFromDate}
                toggleCompareImages={this.toggleCompareImages}
                deleteSelectedImages={this.deleteSelectedImages}
                canView={this.canView}
              />
            </Grid>
          </Hidden>
          <Grid container item sm={9} md={10}>
            <Grid item xs={12}>
              <Preview
                images={images}
                image={images[previewIndex]}
                url={site.url}
                compare={showCompare}
                compareImages={compareImages}
              />

              <Hidden smUp>
                <HorizontalSelector
                  url={site.url}
                  images={images}
                  selectedImages={selectedImages}
                  getImages={this.getImages}
                  previewImage={this.previewImage}
                  toggleSelectImage={this.toggleSelectImage}
                  fromDate={fromDate}
                  setFromDate={this.setFromDate}
                  toggleCompareImages={this.toggleCompareImages}
                  deleteSelectedImages={this.deleteSelectedImages}
                  canView={this.canView}
                />
              </Hidden>

              {site.status != null && <Status site={site} canView={this.canView} />}

              {this.canView([1,2,3]) && <Download site={site} />}

              <Map site={site} />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    const { site } = this.state;

    switch (site) {
      case null:
        return null;
      
      case false:
        return <Typography variant="h4">This site does not support public viewing.</Typography>;
    
      default:
        return this.view();
    }
  }
}


const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {
  setAppLoading,
  addRecent
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Site));