import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography, TextField, Grid, MenuItem, Button, CircularProgress, Hidden, Menu } from '@material-ui/core';
import { SketchPicker } from 'react-color';

function Details(props) {
  const { business, detailsSubmit } = props;

  const [pickerState, setPicker] = useState([false, null, '', 200]);
  const handleSetPicker = evt => {
    setPicker([true, evt.currentTarget, evt.target.name, evt.target.offsetWidth - 20]);
  }

  const [saving, setSaving] = useState(false);
  const account = useSelector(state => state.auth.account);

  const save = evt => {
    evt.preventDefault();
    setSaving(true);
    detailsSubmit().then(() => setSaving(false));
  }

  const safeNull = str => str === null ? "" : str;

  const disableEdit = account.level_id > 2;

  return (
    <form onSubmit={save}>
      <Typography variant="h6" gutterBottom>Details</Typography>
      {/* Color Picker */}
      <Menu
        anchorEl={pickerState[1]}
        keepMounted
        open={pickerState[0]}
        onClose={() => setPicker([false, null, '', 200])}
      >
        <SketchPicker
          color={business[pickerState[2]]}
          onChange={color => props.handleChange(pickerState[2], color.hex)}
          width={pickerState[3]}
        />
      </Menu>

      {/* Detail Entries */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Name"
            name="business_name"
            value={safeNull(business.business_name)}
            onChange={evt => props.handleChange(evt.target.name, evt.target.value)}
            variant="outlined"
            disabled={disableEdit}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            label="Address"
            name="business_address"
            value={safeNull(business.business_address)}
            onChange={evt => props.handleChange(evt.target.name, evt.target.value)}
            variant="outlined"
            disabled={disableEdit}
            fullWidth
            multiline
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            label="City"
            name="business_city"
            value={safeNull(business.business_city)}
            onChange={evt => props.handleChange(evt.target.name, evt.target.value)}
            variant="outlined"
            disabled={disableEdit}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            label="State"
            name="business_state"
            value={safeNull(business.business_state)}
            onChange={evt => props.handleChange(evt.target.name, evt.target.value)}
            variant="outlined"
            disabled={disableEdit}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            label="Country"
            name="business_country"
            value={safeNull(business.business_country)}
            onChange={evt => props.handleChange(evt.target.name, evt.target.value)}
            variant="outlined"
            disabled={disableEdit}
            fullWidth
            select
          >
            <MenuItem value="AUS">Australia</MenuItem>
            <MenuItem value="NZL">New Zealand</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            label="Postcode"
            name="business_zip"
            value={safeNull(business.business_zip)}
            onChange={evt => props.handleChange(evt.target.name, evt.target.value)}
            variant="outlined"
            disabled={disableEdit}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            label="Contact Name"
            name="contact_name"
            value={safeNull(business.contact_name)}
            onChange={evt => props.handleChange(evt.target.name, evt.target.value)}
            variant="outlined"
            disabled={disableEdit}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            label="Contact Phone"
            name="contact_phone"
            value={safeNull(business.contact_phone)}
            onChange={evt => props.handleChange(evt.target.name, evt.target.value)}
            variant="outlined"
            disabled={disableEdit}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            label="Contact Email"
            name="contact_email"
            value={safeNull(business.contact_email)}
            onChange={evt => props.handleChange(evt.target.name, evt.target.value)}
            variant="outlined"
            disabled={disableEdit}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            label="Industry"
            name="industry"
            value={safeNull(business.industry)}
            onChange={evt => props.handleChange(evt.target.name, evt.target.value)}
            variant="outlined"
            disabled={disableEdit}
            fullWidth
            select
          >
            <MenuItem value="Construction">Construction</MenuItem>
            <MenuItem value="Landscaping">Landscaping</MenuItem>
            <MenuItem value="Agriculture">Agriculture</MenuItem>
            <MenuItem value="Horticulture">Horticulture</MenuItem>
            <MenuItem value="Demolition">Demolition</MenuItem>
            <MenuItem value="Civil earthworks">Civil Earthworks</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            label="Website"
            name="website"
            value={safeNull(business.website)}
            onChange={evt => props.handleChange(evt.target.name, evt.target.value)}
            variant="outlined"
            disabled={disableEdit}
            fullWidth
          />
        </Grid>

        {/* Theme Config */}
        <Grid item xs={6} sm={4}>
          <TextField
            label="Background Colour"
            name="theme_primary"
            value={safeNull(business.theme_primary)}
            onChange={evt => props.handleChange(evt.target.name, evt.target.value)}
            variant="outlined"
            disabled={disableEdit}
            required
            fullWidth
            onClick={handleSetPicker}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            label="Text Colour"
            name="theme_secondary"
            value={safeNull(business.theme_secondary)}
            onChange={evt => props.handleChange(evt.target.name, evt.target.value)}
            variant="outlined"
            disabled={disableEdit}
            required
            fullWidth
            onClick={handleSetPicker}
          />
        </Grid>

        {/* Submit */}
        {!disableEdit && <Hidden smUp><Grid item xs={6} sm={false} /></Hidden>}
        {!disableEdit && <Grid item xs={6} sm={4}>
          {saving
            ? <CircularProgress />
            : <Button
                variant="outlined"
                type="submit"
                fullWidth
              >
                Save
              </Button>}
        </Grid>}
      </Grid>
    </form>
  );
}

export default Details;