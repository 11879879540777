import React from 'react';
import ReactCompareImage from 'react-compare-image';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';
import parseUrlToDate from '../../../utils/parseUrlToDate';


function ShowCompare(props) {
  const { compareImages, url } = props;

  const leftImage = compareImages[0];
  const rightImage = compareImages[1];

  const leftURL = `${url}m_${leftImage.url}`
  const rightURL = `${url}m_${rightImage.url}`;

  const leftDate = parseUrlToDate(leftImage.url);
  const rightDate = parseUrlToDate(rightImage.url);

  const leftName = format(leftDate, "d/M/Y h:mm:ss a");
  const rightName = format(rightDate, "d/M/Y h:mm:ss a");
  
  return (
    <div>
      <ReactCompareImage
        leftImage={leftURL}
        rightImage={rightURL}
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {leftName}
        </Grid>
        <Grid item xs={6}>
          {rightName}
        </Grid>
      </Grid>
    </div>
  );
}


function Compare(props) {
  const { compareImages } = props;

  return (
    <div>
      {compareImages.length > 1
        ? ShowCompare(props)
        : <p>Please select another image to compare.</p>
      }
    </div>
  );
}

export default Compare;