import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, LinearProgress } from '@material-ui/core';
import Axios from 'axios';

import TopNav from '../TopNav';
import BottomNav from '../BottomNav';
import { setAppTheme } from '../../../actions';

// Pages
import Account from '../../Account';
import Home from '../../Home';
import Sites from '../../Sites';
import Site from '../../Site';
import Businesses from '../../Businesses';
import Business from '../../Business';


class LoggedIn extends Component {
  componentDidMount() {
    Axios.get('/account/theme')
      .then(res => this.props.setAppTheme(res.data.data))
      .catch(err => console.log(err));
  }

  render() {
    const { app, classes } = this.props;

    return (
      <>
        <TopNav />
        {app.loading && <LinearProgress />}

        <Container maxWidth="lg" className={classes.container}>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/home" component={Home} />
            <Route path="/account" component={Account} />
            <Route path="/sites/:siteID" component={Site} />
            <Route path="/sites" component={Sites} />
            <Route path="/businesses/:businessID" component={Business} />
            <Route path="/businesses" component={Businesses} />
            <Route path="/index.html" component={Home} />

            <Route render={() => <h1>404 Error</h1>}/>
          </Switch>
        </Container>

        <BottomNav />
      </>
    );
  }
}


const mapStateToProps = state => ({
  app: state.app
});

const mapDispatchToProps = {
  setAppTheme
}

export default connect(mapStateToProps, mapDispatchToProps)(LoggedIn);