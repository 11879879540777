import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, Divider, Grid, TextField, Button, IconButton, CircularProgress } from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { setHours, setMinutes, setSeconds, setMilliseconds, format } from 'date-fns';
import { withStyles } from '@material-ui/styles';
import Axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  submitButton: {
    float: 'right',
    marginTop: theme.spacing(1)
  },
  closeButton: {
    float: 'right',
    padding: 6
  }
});

const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];


class TimesegmentsDialog extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.timesegmentsFromString(props.site.timesegments), saving: false };
  }

  timesegmentsFromString(tsString) {
    let split = tsString.split(":");
    var times = split[0].split(",").map(time => {
      let dt = new Date();
      dt = setHours(dt, parseInt(time.substring(0,2)));
      dt = setMinutes(dt, parseInt(time.substring(2, 4)));
      dt = setSeconds(dt, 0);
      dt = setMilliseconds(dt, 0);
      return dt;
    });
    var durations = split.slice(2);
    return {
      tsTimes: times,
      tsDurations: durations,
      timeOffset: split[1]
    }
  }

  stringFromTimesegments() {
    try {
      const { tsDurations, timeOffset } = this.state;
      const tsTimes = this.state.tsTimes.map(time => format(time, "HHmm'00'"));
      return [tsTimes.join(","), timeOffset].concat(tsDurations).join(":");
    } catch (e) {
      if (e instanceof RangeError) {
        // Suppress - likely just a date being changed
      } else {
        throw e;
      }
    }
  }

  handleChange(type, index, val) {
    var next = this.state[type].slice();
    next[index] = val;

    this.setState({
      [type]: next
    });

    this.props.handleDataChange('timesegments', this.stringFromTimesegments());
  }

  handleSubmit(evt) {
    evt.preventDefault();
    this.setState({ saving: true });

    Axios.put(`/account/sites/${this.props.site.site_id}`, {
        timesegments: this.stringFromTimesegments()
      })
      .then(res => {
        this.props.handleClose();
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => this.setState({ saving: false }));
  }

  render() {
    const { open, handleClose, classes } = this.props;
    const { tsTimes, tsDurations, saving } = this.state;
  
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle>
          Timesegments
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
  
        <Divider variant="middle" />
  
        <DialogContent>
          <form onSubmit={evt => this.handleSubmit(evt)}>
            <Grid container spacing={2}>
              {DAYS.map((day, index) => (
                <Grid item container xs={12} spacing={2} key={index}>
                  <Grid item xs={12} sm={2}>
                    <p>{day}</p>
                  </Grid>
    
                  <Grid item xs={6} sm={4}>
                    <KeyboardTimePicker
                      ampm={true}
                      variant="inline"
                      label="Start Time"
                      value={tsTimes[index*2]}
                      inputVariant="outlined"
                      onChange={val => this.handleChange('tsTimes', index*2, val)}
                    />
                  </Grid>
    
                  <Grid item xs={6} sm={4}>
                    <KeyboardTimePicker
                      ampm={true}
                      variant="inline"
                      label="End Time"
                      value={tsTimes[index*2+1]}
                      inputVariant="outlined"
                      onChange={val => this.handleChange('tsTimes', index*2+1, val)}
                    />
                  </Grid>
    
                  <Grid item xs={12} sm={2}>
                    <TextField
                      label="Duration"
                      value={tsDurations[index]}
                      type="number"
                      variant="outlined"
                      onChange={evt => this.handleChange('tsDurations', index, evt.target.value)} />
                  </Grid>
                </Grid>
              ))}
            </Grid>

            {saving
              ? <CircularProgress className={classes.submitButton} />
              : <Button
                  variant="outlined"
                  type="submit"
                  className={classes.submitButton}
                >
                  Submit
                </Button>}
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(TimesegmentsDialog);