import _ from 'lodash';

const initialState = {
  sites: {}
}

const sitesReducer = (state=initialState, action) => {
  var sites;

  switch (action.type) {
    case 'SITES_UPDATE_ALL':
      sites = action.payload.reduce((acc, cur) => {
        acc[cur.site_id] = cur;
        return acc;
      }, {});
      return { sites };
    
    case 'SITES_UPDATE_ONE':
      sites = _.cloneDeep(state.sites);
      sites[action.payload.site_id] = action.payload;
      return { sites };
    
    case 'SITES_RESET':
      return initialState;
  
    default:
      return state;
  } 
}

export default sitesReducer;