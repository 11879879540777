import React from 'react';
import { Typography, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { green, grey, red, deepOrange, lightGreen } from '@material-ui/core/colors';


var batteryColor = grey[500];
const useStyles = makeStyles(theme => ({
  batteryBarPrimary: {
    backgroundColor: 'lightGrey'
  },
  batteryBarSecondary: {
    backgroundColor: batteryColor
  }
}));

function Battery(props) {
  const { status } = props;

  // Determine Battery Charge
  var battery = parseFloat(status.charge);

  // Battery Colour
  if(battery < 25) {
    batteryColor = red[500];
  } else if (battery < 55) {
    batteryColor = deepOrange[400];
  } else if (battery < 85) {
    batteryColor = lightGreen[500];
  } else {
    batteryColor = green[500];
  }

  const classes = useStyles();

  return (
    <div>
      <Typography variant="body1">
        Battery: {parseInt(battery)}%
      </Typography>
      <LinearProgress
        variant="determinate"
        value={battery}
        classes={{
          colorPrimary: classes.batteryBarPrimary,
          barColorPrimary: classes.batteryBarSecondary
        }}
      />
    </div>
  )
}

export default Battery;