import { version } from '../App';

const initialState = {
  version,
  theme: {
    name: "Precision Vision",
    primary: '#2196f3',
    secondary: '#9e9e9e',
    appbar: 'inherit'
  },
  loading: false
}

const appReducer = (state=initialState, action) => {
  switch (action.type) {
    case 'APP_VERSION':
      return {
        ...state,
        version: action.payload
      }

    case 'APP_LOADING':
      return {
        ...state,
        loading: action.payload
      }
    
    case 'APP_THEME':
      return {
        ...state,
        theme: action.payload
      }
    
    case 'APP_RESET':
      return initialState;
  
    default:
      return state;
  }
}

export default appReducer;