import React, { useState } from 'react';
import { TextField, Grid, Button, Typography, CircularProgress } from '@material-ui/core';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { updateAccount } from '../../actions';
import handleError from '../../utils/handleError';


function DetailsForm(props) {
  const { account, handleChange } = props;
  const dispatch = useDispatch();

  const [saving, setSaving] = useState(false);

  const handleSubmit = evt => {
    evt.preventDefault();

    if(!account.username.match(/^[a-z0-9]+$/i)) {
      alert("Username can only contain alphanumeric characters.");
    } else if(account.username.length < 4) {
      alert("Username must be at least 4 characters long.");
    } else {
      setSaving(true);
  
      Axios.put('/account', {
        username: account.username,
        email: account.email
      })
        .then(res => {
          dispatch(updateAccount(account));
        })
        .catch(err => {
          alert(handleError(err));
        })
        .finally(() => {
          setSaving(false);
        });
    }
  }

  return (
    <Grid item sm={6}>
      <Typography variant="h6">Details</Typography>
      <form
        onChange={handleChange}
        onSubmit={handleSubmit}
      >
        <TextField
          name="username"
          label="Username"
          value={account.username}
          margin="normal"
          autoComplete="username"
          fullWidth
          required
        />
        <TextField
          name="email"
          label="Email"
          value={account.email}
          margin="normal"
          autoComplete="email"
          fullWidth
          required
        />
        {saving
          ? <CircularProgress />
          : <Button
              variant="outlined"
              type="submit"
            >
              Save
            </Button>}
      </form>
    </Grid>
  );
}

export default DetailsForm;