import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';

// Components
import LoggedIn from './States/LoggedIn';
import PublicView from './States/PublicView';
import LoggedOut from './States/LoggedOut';


const styles = theme => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(10)
  }
});


class CustomRouter extends Component {
  render() {
    const { auth, classes } = this.props;

    return (
      <Router>
        {auth.loggedIn
          ? <LoggedIn
              classes={classes}
            />
          : <Switch>
              <Route path="/sites/:siteID" render={params => (
                <PublicView
                  match={params.match}
                  classes={classes}
                />
              )}/>
              <Route component={LoggedOut} />
            </Switch>}
      </Router>
    );
  }
}


const mapStateToProps = state => ({
  auth: state.auth
});

export default withStyles(styles)(connect(mapStateToProps)(CustomRouter));