import React from 'react';
import UsersTable from './UsersTable';
import { Typography, IconButton, makeStyles, Tooltip } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';


const useStyles = makeStyles(theme => ({
  addButton: {
    float: 'right'
  }
}));


function Users(props) {
  const { users, setEditUser, openAddUserDialogue, deleteUserSubmit } = props;
  const classes = useStyles();

  return (
    <div>
      <Typography
        variant="h6"
        display="inline"
      >
        Users
      </Typography>

      <Tooltip title="Add User" className={classes.addButton}>
        <IconButton onClick={openAddUserDialogue}>
          <AddIcon />
        </IconButton>
      </Tooltip>

      <UsersTable
        users={users}
        setEditUser={setEditUser}
        deleteUserSubmit={deleteUserSubmit}
      />
    </div>
  );
}

export default Users;