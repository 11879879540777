import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { TableRow, TableCell, IconButton } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';

import CameraIcon from '@material-ui/icons/CameraAlt';
import ArchiveIcon from '@material-ui/icons/Archive';
import LocationOnIcon from '@material-ui/icons/LocationOn';


const useStyles = makeStyles(theme => ({
  button: {
    color: 'black',
    padding: 6
  },
  link: {
    color: 'black'
  }
}));

function SiteRow(props) {
  const { site, moveMap, isAdmin } = props;
  const classes = useStyles();
  

  const goToSite = () => props.history.push(`/sites/${site.site_id}`);

  return (
    <TableRow hover>
      <TableCell>
        <IconButton
          className={classes.button}
          onClick={() => {
            moveMap(parseFloat(site.loc_latitude), parseFloat(site.loc_longitude), 14);
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }}
          disabled={!(site.loc_latitude !== null && site.loc_longitude !== null)}
        >
          <LocationOnIcon />
        </IconButton>
      </TableCell>
      <TableCell onClick={goToSite}>
        <Link
          to={`/sites/${site.site_id}`}
          className={classes.link}
        >
          <IconButton
            className={classes.button}
          >
            { site.archive === 0 ? <CameraIcon /> : <ArchiveIcon /> }
          </IconButton>
        </Link>
      </TableCell>
      <TableCell onClick={goToSite}>{ site.site_id }</TableCell>
      {isAdmin && (
        <TableCell onClick={goToSite}>{ site.business == null ? "" : site.business.business_name }</TableCell>
      )}
      <TableCell onClick={goToSite}>{ site.camera_id }</TableCell>
      <TableCell onClick={goToSite}>{ site.site_name }</TableCell>
      <TableCell onClick={goToSite}>{ site.position }</TableCell>
      <TableCell onClick={goToSite}>{ site.address }</TableCell>
    </TableRow>
  );
}

export default withRouter(SiteRow);