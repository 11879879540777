import React, { Component } from 'react';
import Axios from 'axios';
import Details from './Details';
import Users from './Users';
import { Typography, Grid } from '@material-ui/core';
import _ from 'lodash';
import { connect } from 'react-redux';

import AddUserDialogue from './Dialogs/AddUserDialog';
import EditUserDialogue from './Dialogs/EditUserDialog';
import handleError from '../../utils/handleError';
import { setAppLoading } from '../../actions';


class Business extends Component {
  constructor(props) {
    super(props);
    this.businessID = props.match.params.businessID;

    this.state = {
      business: null,
      addUser: false,
      editUser: null
    }
  }

  componentDidMount() {
    this.props.setAppLoading(true);

    Axios.get(`/account/businesses/${this.businessID}`, {
      params: {
        users: true
      }
    })
      .then(res => {
        // Parse users
        var data = _.cloneDeep(res.data.data);
        if(data.users !== null) {
          data.users = data.users.reduce((acc, cur) => {
            acc[cur.user_id] = cur;
            return acc;
          }, {});
        }
        // Set state
        this.setState({
          business: data
        });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.props.setAppLoading(false);
      });
  }

  detailsSubmit = () => {
    const { business } = this.state;

    return new Promise(resolve => {
      Axios.put(`/account/businesses/${business.business_id}`, _.omit(business, "business_id", "users"))
        .catch(err => {
          alert(handleError(err));
        })
        .finally(() => resolve());
    });
  }

  closeDialogue = () => {
    this.setState({
      addUser: false,
      editUser: null
    });
  }

  setEditUser = data => {
    this.setState({ editUser: data });
  }

  addUserSubmit = user => {
    const { business } = this.state;

    return new Promise(resolve => {
      Axios.post(`/account/businesses/${business.business_id}/users`, user)
        .then(res => {
          var edit = _.cloneDeep(business);
          edit.users[res.data.data.user_id] = res.data.data;
          this.setState({ business: edit });
          this.closeDialogue();
        })
        .catch(err => {
          alert(handleError(err));
        })
        .finally(() => {
          resolve();
        });
    });
  }

  editUserSubmit = (userID, data) => {
    const { business } = this.state;

    return new Promise(resolve => {
      Axios.put(`/account/businesses/${business.business_id}/users/${userID}`, data)
        .then(res => {
          var edit = _.cloneDeep(business);
          edit.users[res.data.data.user_id] = res.data.data;
          this.setState({ business: edit });
        })
        .catch(err => {
          alert(handleError(err));
        })
        .finally(() => resolve());
    });
  }

  deleteUserSubmit = userID => {
    const { business } = this.state;

    if(window.confirm("Are you sure you want to delete this user?")) {
      Axios.delete(`/account/businesses/${business.business_id}/users/${userID}`)
        .then(res => {
          var edit = _.cloneDeep(business);
          delete edit.users[userID];
          this.setState({ business: edit });
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  handleChange = (key, val) => {
    var business = { ...this.state.business };
    business[key] = val;
    this.setState({ business });
  }

  openAddUserDialogue = () => {
    this.setState({
      addUser: true
    });
  }

  view() {
    const { business, addUser, editUser } = this.state;
    const showUsers = business.users !== null;

    return (
      <div>
        <AddUserDialogue
          open={addUser}
          addUserSubmit={this.addUserSubmit}
          handleClose={this.closeDialogue}
        />
        <EditUserDialogue
          editUser={editUser}
          setEditUser={this.setEditUser}
          editUserSubmit={this.editUserSubmit}
          handleChange={this.handleChange}
          handleClose={this.closeDialogue}
        />

        <Typography variant="h4">{business.business_name}</Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={showUsers ? 6 : 12}>
            <Details
              business={business}
              handleChange={this.handleChange}
              detailsSubmit={this.detailsSubmit}
            />
          </Grid>

          {showUsers && <Grid item xs={12} md={6}>
            <Users
              users={business.users}
              openAddUserDialogue={this.openAddUserDialogue}
              setEditUser={this.setEditUser}
              deleteUserSubmit={this.deleteUserSubmit}
            />
          </Grid>}
        </Grid>
      </div>
    )
  }

  render() {
    const { business } = this.state;

    switch (business) {
      case null:
        return null;
    
      default:
        return this.view();
    }
  }
}


const mapDispatchToProps = {
  setAppLoading
}

export default connect(null, mapDispatchToProps)(Business);