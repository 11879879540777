import React from 'react';
import { TableContainer, Paper, Table, TableHead, TableBody, TableRow, TableCell, IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector } from 'react-redux';

function UsersTable(props) {
  const { users, setEditUser, deleteUserSubmit } = props;
  const parsedUsers = Object.values(users);
  var account = useSelector(state => state.auth.account);

  const canUse = user => {
    if((account.level_id === 1) ||
      (account.level_id === 2 && user.level_id > 1) ||
      (account.level_id > user.level_id)) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Options</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parsedUsers.length > 0
            ? parsedUsers.map(user => (
              <TableRow key={user.user_id}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>

                  <Tooltip title="Edit">
                    <span>
                      <IconButton
                        onClick={() => setEditUser({ ...user })}
                        disabled={!canUse(user)}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <span>
                      <IconButton
                        onClick={() => deleteUserSubmit(user.user_id)}
                        disabled={!canUse(user)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>

                </TableCell>
              </TableRow>
            ))
            : <TableRow><TableCell colSpan={4}>This site has no users.</TableCell></TableRow>}
        </TableBody>
      </Table>
    </TableContainer>

  );
}

export default UsersTable;