import React from 'react';
import { Typography, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


var temperatureColour = "white";
const useStyles = makeStyles(theme => ({
  temperatureBarPrimary: {
    backgroundColor: 'lightGrey'
  },
  temperatureBarSecondary: {
    backgroundColor: temperatureColour
  }
}));

function Temperature(props) {
  const { status } = props;

  // Determine temperature
  var temperature = parseFloat(status.temperature);
  if(temperature < 0) {
    temperature = 0;
  } else if(temperature > 100) {
    temperature = 100;
  }

  // Temperature colour
  if(temperature > 60) {
    temperatureColour = "#ff0000"; // Bad (high)
  } else if(temperature > 45) {
    temperatureColour = "#ffff00"; // Alright (medium)
  } else {
    temperatureColour = "#00cc00"; // Good (low)
  }

  const classes = useStyles();

  return (
    <div>
      <Typography variant="body1">
        Temperature: {parseFloat(status.temperature)}{String.fromCharCode(176)}C
      </Typography>
      <LinearProgress
        variant="determinate"
        value={temperature}
        classes={{
          colorPrimary: classes.temperatureBarPrimary,
          barColorPrimary: classes.temperatureBarSecondary
        }}
      />
    </div>
  )
}

export default Temperature;