import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import AddUserForm from './AddUserForm';

class AddUserDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        username: "",
        email: "",
        level_id: 4,
        password: ""
      }
    }
  }

  handleChange = (key, val) => {
    var data = { ...this.state.data };
    data[key] = val;
    this.setState({ data });
  }

  render() {
    const { data } = this.state;
    const { open, handleClose, addUserSubmit } = this.props;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle>Add User</DialogTitle>
  
        <DialogContent>
          <AddUserForm
            user={data}
            handleChange={this.handleChange}
            addUserSubmit={addUserSubmit}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

export default AddUserDialog;