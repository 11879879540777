import _ from 'lodash';

const initialState = {
  businesses: {}
}

const businessesReducer = (state=initialState, action) => {
  var businesses;

  switch (action.type) {
    case 'BUSINESSES_UPDATE_ALL':
      businesses = action.payload.reduce((acc, cur) => {
        acc[cur.business_id] = cur;
        return acc;
      }, {});
      return { businesses };
    
    case 'BUSINESSES_UPDATE_ONE':
      businesses = _.cloneDeep(state.businesses);
      businesses[action.payload.business_id] = action.payload;
      return { businesses };
    
    case 'BUSINESSES_RESET':
      return initialState;
  
    default:
      return state;
  } 
}

export default businessesReducer;