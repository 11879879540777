import React from 'react';
import { AppBar, Toolbar, Typography, Button, Container, IconButton, makeStyles } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';


const useStyles = makeStyles(theme => ({
  account: {
    marginLeft: 'auto'
  },
  homeLink: {
    textDecoration: 'none',
    color: 'inherit'
  },
  logo: {
    width: 50
  }
}));

function TopNav(props) {
  const theme = useSelector(state => state.app.theme);
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const history = useHistory();
  const classes = useStyles();

  return (
    <AppBar position="static" color={'appbar' in theme ? theme.appbar : 'inherit'}>
      <Container maxWidth="lg">
        <Toolbar className={classes.inner} disableGutters>
          <Link to="/" className={classes.homeLink}>
            <Typography variant="h6" color="secondary">
              {theme.name}
            </Typography>
          </Link>

          {loggedIn
            ? <Link to="/account" className={classes.account}>
                <IconButton>
                  <AccountCircleIcon color="secondary" />
                </IconButton>
              </Link>
            : <Button
                className={classes.account}
                onClick={() => history.push('/login')}
                color="inherit"
              >
                Login
              </Button>}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default TopNav;