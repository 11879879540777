import React from 'react';
import { useSelector } from 'react-redux';
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import SiteRow from './SiteRow';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 750
  },
  slim: {
    paddingRight: 0
  }
}));


function SitesTable(props) {
  const { sites, moveMap } = props;
  const classes = useStyles();

  const account = useSelector(state => state.auth.account);
  const isAdmin = account.level_id === 1;

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.slim}>Location</TableCell>
            <TableCell className={classes.slim}>Status</TableCell>
            <TableCell>Site</TableCell>
            {isAdmin && <TableCell>Business</TableCell>}
            <TableCell>Camera</TableCell>
            <TableCell>Site Name</TableCell>
            <TableCell>Position</TableCell>
            <TableCell>Address</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(sites).map(site => (
            <SiteRow
              key={site.site_id}
              site={site}
              moveMap={moveMap}
              isAdmin={isAdmin}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SitesTable;