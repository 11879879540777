import Axios from 'axios';

const initialState = {
  loggedIn: false,
  account: null,
  header: null
}

const authReducer = (state=initialState, action) => {
  var token;

  switch(action.type) {
    case 'AUTH_LOGIN':
      token = btoa(`${action.login.email}:${action.login.password}`);
      var header = `Basic ${token}`;
      Axios.defaults.headers.common['Authorization'] = header;

      return {
        loggedIn: true,
        account: action.payload.account,
        header: header
      };

    case 'AUTH_RESET':
      Axios.defaults.headers.common['Authorization'] = null;

      return initialState;

    case 'AUTH_UPDATE':
      var updated = { ...state };
      updated.account = {
        ...updated.account,
        ...action.payload
      };
      
      token = Axios.defaults.headers.common['Authorization'].replace("Basic ", "");
      var userAuth = atob(token).split(":");

      if("email" in action.payload && "password" in action.payload) {
        token = btoa(`${updated.account.email}:${updated.account.password}`);
      } else if("email" in action.payload) {
        token = btoa(`${updated.account.email}:${userAuth[1]}`);
      } else if("password" in action.payload) {
        token = btoa(`${userAuth[0]}:${updated.account.password}`);
      }

      updated.header = `Basic ${token}`;
      Axios.defaults.headers.common['Authorization'] = updated.header;

      delete updated.account.password;

      return updated;

    default:
      return state;
  }
}

export default authReducer;