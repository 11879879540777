import React, { Component } from 'react';
import { CircularProgress, Typography, Grid, withStyles, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import Axios from 'axios';

import DetailsForm from './DetailsForm';
import PasswordForm from './PasswordForm';
import { setAppLoading, updateAccount } from '../../actions';
import { resetApp, resetAuth, resetSites, resetRecents, resetBusinesses } from '../../actions';
import { withRouter } from 'react-router-dom';


const styles = theme => ({
  title: {
    display: 'inline'
  },
  logoutButton: {
    float: 'right'
  }
});

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: null
    }
  }

  componentDidMount() {
    this.props.setAppLoading(true);

    Axios.get("/account")
      .then(res => {
        this.setState({
          account: res.data.data
        });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        this.props.setAppLoading(false);
      });
  }

  handleChange = evt => {
    var data = { ...this.state };
    data.account[evt.target.name] = evt.target.value;
    this.setState(data);
  }

  render() {
    const { account } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <Typography
          variant="h4"
          className={classes.title}
        >
          Account
        </Typography>

        <Button
          className={classes.logoutButton}
          variant="outlined"
          onClick={() => {
            this.props.resetAuth();
            this.props.resetApp();
            this.props.resetSites();
            this.props.resetRecents();
            this.props.resetBusinesses();
            this.props.history.push('/');
          }}
          color="inherit"
        >
          Logout
        </Button>

        {account === null
          ? <CircularProgress />
          : <Grid container spacing={2}>
            <DetailsForm account={account} handleChange={this.handleChange} />
            <PasswordForm />
          </Grid>}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    account: state.auth.account
  }
}

const mapDispatchToProps = {
  setAppLoading,
  updateAccount,
  resetApp,
  resetAuth,
  resetSites,
  resetRecents,
  resetBusinesses
}

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Account)));