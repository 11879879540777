import React, { Component } from 'react';
import logo from '../../logo.png';
import { withStyles } from '@material-ui/styles';
import { Typography, TextField, Button, CircularProgress } from '@material-ui/core';
import Axios from 'axios';

import { connect } from 'react-redux';
import { login } from '../../actions';
import handleError from '../../utils/handleError';
import { withRouter } from 'react-router-dom';


const styles = theme => ({
  root: {
    marginTop: 150,
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textAlign: 'center'
  },
  logo: {
    width: 500,
    maxWidth: '100%',
    marginBottom: 40,
  },
  form: {
    maxWidth: 300,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  submit: {
  }
});

class Auth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        email: "",
        password: ""
      },
      loading: false
    }
  }

  handleChange(evt) {
    var user = Object.assign(this.state.user);
    user[evt.target.name] = evt.target.value;

    this.setState({
      user: user
    });
  }

  handleSubmit(evt) {
    evt.preventDefault();

    this.setState({ loading: true });

    Axios.post("/login", this.state.user)
      .then(res => {
        this.setState({ loading: false });
        this.props.login(this.state.user, res.data.data);
        this.props.history.push("/");
      })
      .catch(err => {
        alert(handleError(err));
        this.setState({ loading: false });
      });
  }

  render() {
    const { classes } = this.props;
    const { user, loading } = this.state;

    return (
      <div className={classes.root}>
        <img className={classes.logo} src={logo} alt="Logo" />
        <Typography variant="h4">LOGIN</Typography>
  
        <form
          className={classes.form}
          onChange={(evt) => this.handleChange(evt)}
          onSubmit={(evt) => this.handleSubmit(evt)}>
          <TextField
            id="login-email"
            name="email"
            label="Email/User"
            value={user.email}
            variant="outlined"
            margin="normal"
            fullWidth />
          <TextField
            id="login-password"
            name="password"
            label="Password"
            value={user.password}
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth />

          {loading
            ? <CircularProgress />
            : <Button
                className={classes.submit}
                variant="outlined"
                type="submit">
                  Submit
              </Button>}
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = {
  login
}

export default withStyles(styles)(withRouter(connect(null, mapDispatchToProps)(Auth)));