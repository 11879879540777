import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, TextField, Grid, MenuItem, FormGroup, FormControlLabel, Checkbox, Divider, IconButton, Button, CircularProgress, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker } from '@material-ui/pickers';
import _ from 'lodash';
import Axios from 'axios';
import handleError from '../../../utils/handleError';
import { getBusinesses } from '../../../utils/getters';
import { updateBusinesses } from '../../../actions';

const styles = theme => ({
  submitContainer: {
    textAlign: 'right',
    paddingTop: theme.spacing(1)
  },
  closeButton: {
    float: 'right',
    padding: 6
  },
  topDivider: {
    marginBottom: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

export const initialState = {
  data: {
    site_name: "",
    hostname: "",
    position: "",
    ssh_key: "",
    s3storage: "",
    site_ip: "",
    generation: 0,
    gps_capable: 0,
    remote_capable: 0,
    public_view: 0,
    hidden: 0,
    camera_id: 0,
    camera_name: "",
    camera_model: "",
    software_version: "",
    deepsleep: 0,
    maintenance_mode: 0,
    continuous: 0,
    address: "",
    client_contact: "",
    client_phone: "",
    client_email: "",
    manager_contact: "",
    manager_phone: "",
    manager_email: "",
    loc_latitude: "",
    loc_longitude: "",
    description: "",
    start_date: null,
    completion_date: null,
    nonbilling_start: null,
    nonbilling_end: null,
    billing_rate: 0,
    archive: 0,
    business_id: 0,
    timesegments: "080000,180000,080000,180000,080000,180000,080000,180000,080000,180000,080000,180000,080000,180000:13:60:60:60:60:60:60:60"
  },
  saving: false
}


class AddSiteDialog extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount() {
    getBusinesses()
      .then(res => this.props.updateBusinesses(res.data.data))
      .catch(err => console.log(err));
  }

  handleChange = (key, val) => {
    var data = { ...this.state.data };
    data[key] = val;
    this.setState({ data });
  };

  handleSubmit = evt => {
    evt.preventDefault();
    this.setState({ saving: true });

    const data = _.omit(this.state.data, ['site_id', 'status', 'url']);

    Axios.post('/account/sites', data)
      .then(res => {
        this.props.addSite(res.data.data);
        this.props.handleClose();
        this.setState(initialState);
      })
      .catch(err => {
        this.setState({ saving: false });
        alert(handleError(err));
      });
  }

  render() {
    const { saving, data } = this.state;
    const { open, handleClose, classes } = this.props;
  
    const toBool = io => parseInt(io) === 1;
    const safeNull = str => str === null ? "" : str;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle>
          Site Setup
          <IconButton className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
        </DialogTitle>
  
        <Divider className={classes.topDivider} />
  
        {/* SITE */}
        <DialogContent className={classes.content}>
          <form onSubmit={evt => this.handleSubmit(evt)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Site Name"
                  variant="outlined"
                  name="site_name"
                  value={safeNull(data.site_name)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Hostname"
                  variant="outlined"
                  name="hostname"
                  value={safeNull(data.hostname)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Position"
                  variant="outlined"
                  name="position"
                  value={safeNull(data.position)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="SSH Key"
                  variant="outlined"
                  name="ssh_key"
                  value={safeNull(data.ssh_key)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="S3 Storage"
                  variant="outlined"
                  name="s3storage"
                  value={data.s3storage}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  label="Site IP"
                  variant="outlined"
                  name="site_ip"
                  value={safeNull(data.site_ip)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  select
                  label="Generation"
                  variant="outlined"
                  name="generation"
                  value={safeNull(data.generation)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                >
                  <MenuItem value={0}>Undefined</MenuItem>
                  <MenuItem value={1}>Gen 1</MenuItem>
                  <MenuItem value={2}>Gen 2</MenuItem>
                  <MenuItem value={3}>Gen 3</MenuItem>
                  <MenuItem value={4}>Gen 4</MenuItem>
                  <MenuItem value={5}>Gen 5</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  select
                  label="Business"
                  variant="outlined"
                  name="business_id"
                  value={safeNull(data.business_id)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                >
                  <MenuItem value={0}>Undefined</MenuItem>
                  {Object.values(this.props.businesses).map(business => (
                    <MenuItem
                      key={business.business_id}
                      value={business.business_id}
                    >
                      {business.business_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
  
              <Grid item xs={12}>
                <FormGroup row>
                  <FormControlLabel control={
                      <Checkbox
                        color="primary"
                        name="gps_capable"
                        checked={toBool(data.gps_capable)}
                        onChange={evt => this.handleChange(evt.target.name, evt.target.checked ? 1 : 0)}
                      />
                    }
                    label="GPS Capable"
                  />
                  <FormControlLabel control={
                      <Checkbox
                        color="primary"
                        name="remote_capable"
                        checked={toBool(data.remote_capable)}
                        onChange={evt => this.handleChange(evt.target.name, evt.target.checked ? 1 : 0)}
                      />
                    }
                    label="Remote Capable"
                  />
                  <FormControlLabel control={
                      <Checkbox
                        color="primary"
                        name="public_view"
                        checked={toBool(data.public_view)}
                        onChange={evt => this.handleChange(evt.target.name, evt.target.checked ? 1 : 0)}
                      />
                    }
                    label="Public Viewing"
                  />
                  <FormControlLabel control={
                      <Checkbox
                        color="primary"
                        name="hidden"
                        checked={toBool(data.hidden)}
                        onChange={evt => this.handleChange(evt.target.name, evt.target.checked ? 1 : 0)}
                      />
                    }
                    label="Hide Status View"
                  />
                </FormGroup>
              </Grid>
            </Grid>
  
            <Divider className={classes.divider} />
  
            {/* CAMERA */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Camera ID"
                  variant="outlined"
                  name="camera_id"
                  type="number"
                  value={safeNull(data.camera_id)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Camera Name"
                  variant="outlined"
                  name="camera_name"
                  value={safeNull(data.camera_name)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Camera Model"
                  variant="outlined"
                  name="camera_model"
                  value={safeNull(data.camera_model)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  label="Software Version"
                  variant="outlined"
                  name="software_version"
                  value={safeNull(data.software_version)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
  
            <Divider className={classes.divider} />
  
            {/* MODES */}
            <FormGroup row>
              <FormControlLabel control={
                  <Checkbox
                    color="primary"
                    name="deepsleep"
                    checked={toBool(data.deepsleep)}
                    onChange={evt => this.handleChange(evt.target.name, evt.target.checked ? 1 : 0)}
                  />
                }
                label="Deep Sleep Mode"
              />
              <FormControlLabel control={
                  <Checkbox
                    color="primary"
                    name="maintenance_mode"
                    checked={toBool(data.maintenance_mode)}
                    onChange={evt => this.handleChange(evt.target.name, evt.target.checked ? 1 : 0)}
                  />
                }
                label="Maintenance Mode"
              />
              <FormControlLabel control={
                  <Checkbox
                    color="primary"
                    name="continuous"
                    checked={toBool(data.continuous)}
                    onChange={evt => this.handleChange(evt.target.name, evt.target.checked ? 1 : 0)}
                  />
                }
                label="Continuous Mode"
              />
            </FormGroup>
  
            <Divider className={classes.divider} />
  
            {/* MISC */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Address"
                  variant="outlined"
                  name="address"
                  value={safeNull(data.address)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  rows={4}
                  multiline
                  fullWidth
                />
              </Grid>
  
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Client Name"
                  variant="outlined"
                  name="client_contact"
                  value={safeNull(data.client_contact)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Client Phone"
                  variant="outlined"
                  name="client_phone"
                  value={safeNull(data.client_phone)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Client Email"
                  variant="outlined"
                  name="client_email"
                  value={safeNull(data.client_email)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                />
              </Grid>
  
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Manager Name"
                  variant="outlined"
                  name="manager_contact"
                  value={safeNull(data.manager_contact)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Manager Phone"
                  variant="outlined"
                  name="manager_phone"
                  value={safeNull(data.manager_phone)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Manager Email"
                  variant="outlined"
                  name="manager_email"
                  value={safeNull(data.manager_email)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                />
              </Grid>
  
              <Grid item xs={6}>
                <TextField
                  label="Latitude"
                  variant="outlined"
                  name="loc_latitude"
                  value={safeNull(data.loc_latitude)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Longitude"
                  variant="outlined"
                  name="loc_longitude"
                  value={safeNull(data.loc_longitude)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                />
              </Grid>
  
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  variant="outlined"
                  name="description"
                  value={safeNull(data.description)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  rows={4}
                  multiline
                  fullWidth
                />
              </Grid>
            </Grid>
  
            <Divider className={classes.divider} />
  
            {/* BILLING */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <KeyboardDatePicker
                  label="Billing Start"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  value={data.start_date}
                  onChange={val => this.handleChange(
                    'start_date',
                    val === null ? null : val.toISOString()
                  )}
                  fullWidth
                  clearable
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <KeyboardDatePicker
                  label="Billing End"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  value={data.completion_date}
                  onChange={val => this.handleChange(
                    'completion_date',
                    val === null ? null : val.toISOString()
                  )}
                  fullWidth
                  clearable
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <KeyboardDatePicker
                  label="Billing Paused From"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  value={data.nonbilling_start}
                  onChange={val => this.handleChange(
                    'nonbilling_start',
                    val === null ? null : val.toISOString()
                  )}
                  fullWidth
                  clearable
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <KeyboardDatePicker
                  label="Billing Paused To"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  value={data.nonbilling_end}
                  onChange={val => this.handleChange(
                    'nonbilling_end',
                    val === null ? null : val.toISOString()
                  )}
                  fullWidth
                  clearable
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Billing Rate"
                  variant="outlined"
                  type="number"
                  name="billing_rate"
                  value={safeNull(data.billing_rate)}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.submitContainer}>
                {saving
                ? <CircularProgress />
                : <Button
                    variant="outlined"
                    type="submit"
                  >
                    Submit
                  </Button>}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}


const mapStateToProps = state => ({
  businesses: state.businesses.businesses
});

const mapDispatchToProps = {
  updateBusinesses
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddSiteDialog));