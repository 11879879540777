import React, { Component } from 'react';
import { Grid, Typography, CircularProgress, Button, TextField, MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';

class AddUserForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: {
        new: "",
        confirm: ""
      },
      saving: false
    }
  }

  levelFilter(levelID) {
    const permLevel = this.props.auth.account.level_id;
    if(permLevel <= 2) {
      return permLevel > levelID;
    } else {
      return permLevel + 1 > levelID;
    }
  }

  handlePasswordChange = evt => {
    var data = { ...this.state.password };
    data[evt.target.name] = evt.target.value;
    this.setState({ password: data });
    this.props.handleChange('password', this.state.password.new);
  }

  save = evt => {
    evt.preventDefault();

    const { user } = this.props;
    const { password } = this.state;

    if(!user.username.match(/^[a-z0-9]+$/i)) {
      alert("Username must be alphanumeric.");
    } else if(user.username.length < 4) {
      alert("Username must be at least 4 characters long.");
    } else if(password.new.length < 5) {
      alert("Password must be at least 5 characters long.");
    } else if(password.new.includes(":")) {
      alert("Password cannot contain a ':' character.");
    } else if(password.new !== password.confirm) {
      alert("Passwords must match.");
    } else {
      this.setState({ savingPassword: true });
  
      this.props.addUserSubmit(user).then(() => {
        this.setState({ saving: false });
      });
    }
  }

  render() {
    const { saving } = this.state;
    const { user, handleChange } = this.props;
  
    return (
      <form
        onSubmit={this.save}
      >
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography variant="h6">Details</Typography>
            <TextField
              label="Username"
              name="username"
              onChange={evt => handleChange(evt.target.name, evt.target.value)}
              variant="outlined"
              margin="normal"
              value={user.username}
              fullWidth
              required
            />
            <TextField
              label="Email"
              name="email"
              onChange={evt => handleChange(evt.target.name, evt.target.value)}
              variant="outlined"
              margin="normal"
              value={user.email}
              fullWidth
              required
            />
            <TextField
              label="Level"
              name="level_id"
              onChange={evt => handleChange(evt.target.name, evt.target.value)}
              variant="outlined"
              margin="normal"
              value={user.level_id}
              fullWidth
              select
              required
            >
              <MenuItem value={1} disabled={this.levelFilter(1)}>Admin</MenuItem>
              <MenuItem value={2} disabled={this.levelFilter(2)}>Business Owner</MenuItem>
              <MenuItem value={3} disabled={this.levelFilter(3)}>Staff</MenuItem>
              <MenuItem value={4} disabled={this.levelFilter(4)}>Customer</MenuItem>
            </TextField>
          </Grid>
  
          <Grid item sm={6}>
            <Typography variant="h6">Password</Typography>
              <TextField
                label="New Password"
                name="new"
                onChange={this.handlePasswordChange}
                variant="outlined"
                margin="normal"
                type="password"
                fullWidth
                required
              />
              <TextField
                label="Confirm Password"
                name="confirm"
                onChange={this.handlePasswordChange}
                variant="outlined"
                margin="normal"
                type="password"
                fullWidth
                required
              />
              <div style={{float: 'right', marginTop: 25 }}>
                {saving
                  ? <CircularProgress />
                  : <Button
                      variant="outlined"
                      type="submit"
                    >
                      Save
                    </Button>}
              </div>
          </Grid>
        </Grid>
      </form>
    );

  }
}


const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(AddUserForm);