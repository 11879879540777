import React, { Component } from 'react';
import { withStyles, Button } from '@material-ui/core';
import { setAppLoading, updateSites } from '../../actions';
import { getSites } from '../../utils/getters';
import { connect } from 'react-redux';

import Map from '../SharedComponents/UserSitesMap';
import SitesTable from './SitesTable';
import AddSiteDialog from '../Sites/Dialogues/AddSite';

const styles = theme => ({
  addSiteButton: {
    marginBottom: theme.spacing(1)
  }
});


class Sites extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapPosition: {
        center: {
          lat: -34,
          lng: 165
        },
        zoom: 4
      },
      showAddDialog: false
    }
  }

  componentDidMount() {
    const isAdmin = this.props.account.level_id === 1;
    this.props.setAppLoading(true);
    getSites({ small: true, latestImage: true, business_name: isAdmin})
      .then(res => this.props.updateSites(res.data.data))
      .catch(err => console.log(err))
      .finally(() => this.props.setAppLoading(false));
  }

  showAddDialog = (show=!this.state.showAddDialog) => {
    this.setState({
      showAddDialog: show
    });
  }

  addSite = data => {
    var sites = Object.values(this.props.sites);
    sites.push(data);
    this.props.updateSites(sites);
  }

  moveMap = (lat, lng, zoom=this.state.mapPosition.zoom) => {
    this.setState({
      mapPosition: {
        center: {
          lat: lat,
          lng: lng
        },
        zoom: zoom
      }
    });
  }

  render() {
    const { showAddDialog, mapPosition } = this.state;
    const { account, sites, classes } = this.props;

    const isAdmin = account.level_id === 1;

    return (
      <div>
        {isAdmin && <AddSiteDialog
          open={showAddDialog}
          addSite={this.addSite}
          handleClose={() => this.showAddDialog(false)}
        />}

        <Map
          sites={sites}
          position={mapPosition}
        />

        {isAdmin && <Button
          variant='outlined'
          className={classes.addSiteButton}
          onClick={() => this.showAddDialog(true)}
        >
          Add Site
        </Button>}
        <SitesTable
          sites={sites}
          moveMap={this.moveMap}
        />
      </div>
    );
  }
}



const mapStateToProps = state => {
  return {
    account: state.auth.account,
    sites: state.sites.sites
  }
}

const mapDispatchToProps = {
  setAppLoading,
  updateSites
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Sites));