// APP
export const setAppVersion = version => {
  return {
    type: 'APP_VERSION',
    payload: version
  }
}

export const setAppTheme = data => {
  return {
    type: 'APP_THEME',
    payload: data
  }
}

export const setAppLoading = state => {
  return {
    type: 'APP_LOADING',
    payload: state
  }
}

export const resetApp = () => {
  return {
    type: 'APP_RESET'
  }
}


// AUTH
export const login = (login, data) => {
  return {
    type: 'AUTH_LOGIN',
    payload: data,
    login
  }
}

export const resetAuth = () => {
  return {
    type: 'AUTH_RESET'
  }
}

export const updateAccount = data => {
  return {
    type: 'AUTH_UPDATE',
    payload: data
  }
}


// RECENTS
export const addRecent = siteID => {
  return {
    type: 'RECENTS_ADD',
    payload: siteID
  }
}

export const resetRecents = () => {
  return {
    type: 'RECENTS_RESET'
  }
}


// SITES
export const updateSites = sites => {
  return {
    type: 'SITES_UPDATE_ALL',
    payload: sites
  }
}

export const updateSite = site => {
  return {
    type: 'SITES_UPDATE_ONE',
    payload: site
  }
}

export const resetSites = () => {
  return {
    type: 'SITES_RESET'
  }
}


// BUSINESSES
export const updateBusinesses = businesses => {
  return {
    type: 'BUSINESSES_UPDATE_ALL',
    payload: businesses
  }
}

export const updateBusiness = business => {
  return {
    type: 'BUSINESSES_UPDATE_ONE',
    payload: business
  }
}

export const resetBusinesses = () => {
  return {
    type: 'BUSINESSES_RESET'
  }
}