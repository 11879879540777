import appReducer from './app';
import authReducer from './auth';
import recentsReducer from './recents';
import sitesReducer from './sites';
import businessesReducer from './businesses'
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const appPersistConfig = {
  key: 'app',
  storage
}

const authPersistConfig = {
  key: 'auth',
  storage
}

const recentsPersistConfig = {
  key: 'recents',
  storage
}

const sitesPersistConfig = {
  key: 'sites',
  storage
}

const businessesPersistConfig = {
  key: 'businesses',
  storage
}

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  auth: persistReducer(authPersistConfig, authReducer),
  recents: persistReducer(recentsPersistConfig, recentsReducer),
  sites: persistReducer(sitesPersistConfig, sitesReducer),
  businesses: persistReducer(businessesPersistConfig, businessesReducer)
});

export default rootReducer;