import React, { Component } from 'react';
import { Paper, Grid, TextField, MenuItem, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, withStyles, Button } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { subDays, set, formatISO } from 'date-fns';
import nanoid from 'nanoid';
import Axios from 'axios';
import _ from 'lodash';
import DownloadTable from './DownloadTable';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  downloadButton: {
    marginTop: theme.spacing(2),
    float: 'right'
  }
});

class Download extends Component {
  constructor(props) {
    super(props);

    this.state = {
      spec: {
        from_date: subDays(set(new Date(), {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0
        }), 1),
        to_date: set(new Date(), {
          hours: 23,
          minutes: 59,
          seconds: 59,
          milliseconds: 999
        }),
        framerate: 16,
        resolution: '1920x1080',
        format: 'mp4'
      },
      downloads: {}
    }
  }

  componentDidMount() {
    const { site_id } = this.props.site;

    Axios.get(`account/sites/${site_id}/exports`)
      .then(res => {
        res.data.data.forEach(row => {
          this.setStream(nanoid(), row);
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleChange(key, val) {
    var spec = _.cloneDeep(this.state.spec);
    spec[key] = val;

    this.setState({ spec });
  }

  setStream = (streamKey, data) => {
    var downloads = _.cloneDeep(this.state.downloads);
    downloads[streamKey] = Object.assign({}, downloads[streamKey], data);
    this.setState({ downloads });
  }

  deleteStream = streamKey => {
    var downloads = _.cloneDeep(this.state.downloads);
    delete downloads[streamKey];
    this.setState({ downloads });
  }

  generateTimelapse() {
    const { spec } = this.state;
    const { site_id } = this.props.site;
    
    const type = spec.format;

    var streamKey = nanoid();

    switch (type) {
      case 'mp4':
        this.setStream(streamKey, {
          created: new Date(),
          from_date: formatISO(spec.from_date),
          to_date: formatISO(spec.to_date),
          framerate: spec.framerate,
          resolution: spec.resolution,
          format: spec.format,
          path: null
        });

        Axios.get(`/account/sites/${site_id}/download-video`, {
          params: {
            from_date: formatISO(spec.from_date),
            to_date: formatISO(spec.to_date),
            framerate: spec.framerate,
            resolution: spec.resolution
          }
        }).then(res => {
            this.setStream(streamKey, res.data.data); // Success
          }).catch(err => {
            console.log(err.response);
            this.setStream(streamKey, { url: false }); // Failed
          });
        break;
      
      case 'zip':
        this.setStream(streamKey, {
          created: new Date(),
          from_date: formatISO(spec.from_date),
          to_date: formatISO(spec.to_date),
          framerate: null,
          resolution: null,
          format: spec.format,
          path: null
        });

        Axios.get(`/account/sites/${site_id}/download-zip`, {
          params: {
            from_date: formatISO(spec.from_date),
            to_date: formatISO(spec.to_date)
          }
        }).then(res => {
            this.setStream(streamKey, res.data.data); // Success
          }).catch(err => {
            console.log(err.response);
            this.setStream(streamKey, { url: false }); // Failed
          });
        break
    
      default:
        break;
    }
  }

  deleteExport = streamKey => {
    this.setStream(streamKey, { path: null });

    const { site_id } = this.props.site;
    const id = this.state.downloads[streamKey].export_id;

    Axios.delete(`/account/sites/${site_id}/exports/${id}`)
      .then(res => {
        this.deleteStream(streamKey)
      })
      .catch(err => {
        alert("Delete failed.");
        console.log(err.response);
      });
  }

  handleSubmit = evt => {
    evt.preventDefault();

    const { from_date, to_date, format } = this.state.spec;
    const { site_id } = this.props.site;

    Axios.get(`/account/sites/${site_id}/images/count-in-range`, {
      params: {
        from_date: formatISO(from_date),
        to_date: formatISO(to_date)
      }
    }).then(res => {
      if(window.confirm(`Create ${format === 'mp4' ? 'video' : format} from ${res.data.data.imageCount} images?`)) {
        this.generateTimelapse(res.data.data.imageCount);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  render() {
    const { classes } = this.props;
    const { spec, downloads } = this.state;

    const isZip = spec.format === 'zip';

    return (
      <Paper className={classes.root}>
        <form onSubmit={this.handleSubmit}>
          <Grid container item spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <KeyboardDatePicker
                value={spec.from_date}
                onChange={date => this.handleChange('from_date', date)}
                format="dd/MM/yyyy"
                label="Start Date"
                inputVariant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <KeyboardDatePicker
                value={spec.to_date}
                onChange={date => this.handleChange('to_date', date)}
                format="dd/MM/yyyy"
                label="End Date"
                inputVariant="outlined"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                select
                label="Resolution"
                value={spec.resolution}
                onChange={evt => this.handleChange('resolution', evt.target.value)}
                variant="outlined"
                disabled={isZip}
                fullWidth
              >
                <MenuItem value="720x480">720×480</MenuItem>
                <MenuItem value="720x576">720×576</MenuItem>
                <MenuItem value="1280x720">1280×720</MenuItem>
                <MenuItem value="1920x1080">1920×1080</MenuItem>
                <MenuItem value="3840x2160">3840×2160</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                select
                label="Frame Rate"
                value={spec.framerate}
                onChange={evt => this.handleChange('framerate', evt.target.value)}
                variant="outlined"
                disabled={isZip}
                fullWidth
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={16}>16</MenuItem>
                <MenuItem value={24}>24</MenuItem>
                <MenuItem value={32}>32</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={7} sm={6} md={4}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Format</FormLabel>
                <RadioGroup
                  value={spec.format}
                  onChange={evt => this.handleChange('format', evt.target.value)}
                  row
                >
                  <FormControlLabel value="mp4" control={<Radio color="primary" />} label="MP4" />
                  <FormControlLabel value="zip" control={<Radio color="primary" />} label="ZIP" />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={5} sm={6} md={4}>
              <Button
                variant="outlined"
                className={classes.downloadButton}
                type="submit"
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </form>

        <Grid item xs={12}>
          <DownloadTable downloads={downloads} deleteExport={this.deleteExport} />
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(Download);