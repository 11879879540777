import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';

import Image from './Image';
import Compare from './Compare';


const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    padding: 10
  }
}));


function Preview(props) {
  const { compare, images } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      {images.length === 0
        ? <p>No images to display</p>
        : compare ? <Compare {...props} /> : <Image {...props} />
      }
    </Paper>
  );
}

export default Preview;