import React from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Battery from './Battery';
import Temperature from './Temperature';
import Signal from './Signal';
import { format } from 'date-fns';
import parseWake from '../../../utils/parseWake';


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  }
}));


function Status(props) {
  const { site, canView } = props;
  const { status } = site;

  // Get classes
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="body1">
            Next Photo: <b>{format(parseWake(status.wake), "d/M/y h:mm:ssa")}</b>
          </Typography>
        </Grid>
        {canView([1,2,3]) && <Grid item xs={6}>
          <Battery status={status} />
        </Grid>}
        {canView([1,2,3]) && <Grid item xs={6}>
          {site.generation >= 5 ? <Signal status={status} /> : <Temperature status={status} />}
        </Grid>}
      </Grid>
    </Paper>
  );
}

export default Status;