import Axios from "axios"

export const getSites = (options={small: false, latestImage: false, status: false, business_name: false}) => {
  return Axios.get('/account/sites', {
    params: {
      small: options.small,
      'latest-image': options.latestImage,
      status: options.status,
      business_name: options.business_name
    }
  });
}

export const getBusinesses = () => {
  return Axios.get('/account/businesses');
}