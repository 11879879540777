const initialState = {
  recents: []
}

const recentsReducer = (state=initialState, action) => {
  switch(action.type) {
    case 'RECENTS_ADD':
      var recents = state.recents.slice();
      var index = recents.indexOf(action.payload);
      if(index > -1) {
        recents.splice(index, 1);
      }
      recents.unshift(action.payload);
      return { recents };

    case 'RECENTS_RESET':
      return initialState;

    default:
      return state;
  }
}

export default recentsReducer;