import React from 'react';
import { Typography, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


var signalColour = "white";
const useStyles = makeStyles(theme => ({
  signalBarPrimary: {
    backgroundColor: 'lightGrey'
  },
  signalBarSecondary: {
    backgroundColor: signalColour
  }
}));

function Signal(props) {
  const { status } = props;

  // Signal Colour
  var signal = parseInt(status.signal);
  switch (signal) {
    case 1:
      signalColour = "#ff0000"; // Poor
      break;
    case 2:
      signalColour = "#ff9900"; // Weak
      break;
    case 3:
      signalColour = "#ffff00"; // Alright
      break;
    case 4:
      signalColour = "#ccff33"; // Good
      break;
    case 5:
      signalColour = "#00cc00"; // Great
      break;
    default:
      break;
  }
  
  const classes = useStyles();
  
  return (
    <div>
      <Typography variant="body1">
        Signal: {signal}/5
      </Typography>
      <LinearProgress
        variant="determinate"
        value={signal * 20}
        classes={{
          colorPrimary: classes.signalBarPrimary,
          barColorPrimary: classes.signalBarSecondary
        }}
      />
    </div>
  )
}

export default Signal;