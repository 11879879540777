import React from 'react';
import GoogleMapReact from 'google-map-react';
import { makeStyles } from '@material-ui/styles';

import LocationIcon from './LocationIcon';

const useStyles = makeStyles(theme => ({
  map: {
    height: '40vh',
    width: '100%',
    marginBottom: theme.spacing(2)
  }
}));

function Map(props) {
  const { sites, position } = props;
  const classes = useStyles();

  const locations = Object.values(sites).reduce((acc, cur) => {
    if(cur.loc_latitude !== null && cur.loc_longitude !== null) {
      acc.push(cur);
    }
    return acc;
  }, []);

  return (
    <div className={classes.map}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDFUe7M41gqlkbQh6G4mRXxwl_dxPugjbU" }}
        center={position.center}
        zoom={position.zoom}
      >
        {locations.map(site => (
          <LocationIcon
            key={site.site_id}
            site={site}
            lat={site.loc_latitude}
            lng={site.loc_longitude}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
}

export default Map;