import React from 'react';
import { connect } from 'react-redux';
import { resetRecents } from '../../../actions';

import SiteCard from './SiteCard';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    maxHeight: 400,
    overflowY: 'scroll'
  }
}));

function SitesOverview(props) {
  const classes = useStyles();
  const { sites, recents } = props;

  // TEMP
  try {
    recents.map(item => item);
  } catch (e) {
    switch (e.name) {
      case 'TypeError':
        props.resetRecents();
        break;

      default:
        break;
    }
  }

  var order = recents.reduce((acc, cur) => {
    if(sites[cur] !== undefined) {
      acc.push(sites[cur]);
    }
    return acc;
  }, []);

  Object.values(sites).map(site => {
    if(!order.includes(site)) {
      order.push(site);
    }
    return null;
  });
  
  const filteredOrder = order.filter(site => site.archive === 0);

  return (
    <Grid container spacing={2} className={classes.container}>
      {filteredOrder.map(site => (
        <SiteCard key={site.site_id} site={site} />
      ))}
    </Grid>
  );
}

const mapStateToProps = state => ({
  sites: state.sites.sites,
  recents: state.recents.recents
});

const mapDispatchToProps = {
  resetRecents
}

export default connect(mapStateToProps, mapDispatchToProps)(SitesOverview);