import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Grid, MenuItem, FormGroup, FormControlLabel, Checkbox, Divider, IconButton, Button, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Axios from 'axios';
import _ from 'lodash';
import handleError from '../../../utils/handleError';
import { formatISO } from 'date-fns';
import { initialState } from '../../Sites/Dialogues/AddSite';

const useStyles = makeStyles(theme => ({
  submitButton: {
    float: 'right',
    marginTop: theme.spacing(1)
  },
  closeButton: {
    float: 'right',
    padding: 6
  },
  archiveButton: {
    float: 'right',
    marginRight: theme.spacing(2)
  },
  topDivider: {
    marginBottom: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));


function EditSiteDialog(props) {
  const { site, open, handleDataChange, handleClose, canView } = props;
  const classes = useStyles();

  const [saving, setSaving] = useState(false);
  const [archiving, setArchiving] = useState(false);

  const toBool = io => parseInt(io) === 1;
  const safeNull = str => str === null ? "" : str;

  const handleChange = evt => {
    var value = null;
    switch (evt.target.type) {
      case "checkbox":
        value = evt.target.checked ? 1 : 0;
        break;
    
      default:
        value = evt.target.value;
        break;
    }

    handleDataChange(evt.target.name, value);
  };

  const handleSubmit = evt => {
    evt.preventDefault();
    submit();
  }

  const submit = (useData=site) => {
    setSaving(true);

    const data = _.omit(useData, ['site_id', 'status', 'url', 'timesegments']);

    Axios.put(`/account/sites/${useData.site_id}`, data)
      .then(res => {
        props.handleClose();
      })
      .catch(err => {
        alert(handleError(err));
      })
      .finally(() => setSaving(false));
  }

  const archive = () => {
    if(window.confirm("Are you sure you want to archive this site?")) {
      setArchiving(true);
      const now = formatISO(new Date());

      var newSiteData = Object.assign({}, initialState.data, _.pick(site,
        ['site_name', 'hostname', 'position', 'ssh_key', 's3storage', 'site_ip',
        'generation', 'gps_capable', 'remote_capable', 'public_view', 'hidden', 'camera_id',
        'camera_name', 'camera_model', 'software_version', 'deepsleep', 'maintenance_mode', 'continuous', 
        'business_id', 'timezone', 'timesegments']));
      newSiteData.start_date = now;

      handleDataChange('completion_date', now)
        .then(() => handleDataChange('archive', 1));
      submit({
        ...site,
        completion_date: now,
        archive: 1
      });

      Axios.post('/account/sites', newSiteData)
        .catch(err => {
          alert(handleError(err));
        })
        .finally(() => setArchiving(false));
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle>
        Site Setup
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        {canView([1]) && archiving
          ? <CircularProgress className={classes.archiveButton} />
          : <Button
              variant="outlined"
              className={classes.archiveButton}
              onClick={archive}
            >
              Archive
            </Button>}
      </DialogTitle>

      <Divider className={classes.topDivider} />

      {/* SITE */}
      <DialogContent className={classes.content}>
        <form onChange={handleChange} onSubmit={evt => handleSubmit(evt)}>
          <Grid container spacing={2}>
            {canView([1]) && <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Site Name"
                variant="outlined"
                name="site_name"
                value={safeNull(site.site_name)}
                fullWidth
                required
              />
            </Grid>}
            {canView([1]) && <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Hostname"
                variant="outlined"
                name="hostname"
                value={safeNull(site.hostname)}
                fullWidth
                required
              />
            </Grid>}
            {canView([1]) && <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Position"
                variant="outlined"
                name="position"
                value={safeNull(site.position)}
                fullWidth
              />
            </Grid>}
            {canView([1]) && <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="SSH Key"
                variant="outlined"
                name="ssh_key"
                value={safeNull(site.ssh_key)}
                fullWidth
              />
            </Grid>}
            {canView([1]) && <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="S3 Storage"
                variant="outlined"
                name="s3storage"
                value={site.s3storage}
                fullWidth
                required
              />
            </Grid>}
            {canView([1]) && <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Site IP"
                variant="outlined"
                name="site_ip"
                value={safeNull(site.site_ip)}
                fullWidth
              />
            </Grid>}
            {canView([1]) && <Grid item xs={12} sm={6} md={4}>
              <TextField
                select
                label="Generation"
                variant="outlined"
                name="generation"
                value={safeNull(site.generation)}
                onChange={handleChange}
                fullWidth
                required
              >
                <MenuItem value={0}>Undefined</MenuItem>
                <MenuItem value={1}>Gen 1</MenuItem>
                <MenuItem value={2}>Gen 2</MenuItem>
                <MenuItem value={3}>Gen 3</MenuItem>
                <MenuItem value={4}>Gen 4</MenuItem>
                <MenuItem value={5}>Gen 5</MenuItem>
              </TextField>
            </Grid>}

            {canView([1]) && <Grid item xs={12}>
              <FormGroup row>
                <FormControlLabel control={
                    <Checkbox
                      color="primary"
                      name="gps_capable"
                      checked={toBool(site.gps_capable)}
                    />
                  }
                  label="GPS Capable"
                />
                <FormControlLabel control={
                    <Checkbox
                      color="primary"
                      name="remote_capable"
                      checked={toBool(site.remote_capable)}
                    />
                  }
                  label="Remote Capable"
                />
                <FormControlLabel control={
                    <Checkbox
                      color="primary"
                      name="public_view"
                      checked={toBool(site.public_view)}
                    />
                  }
                  label="Public Viewing"
                />
                <FormControlLabel control={
                    <Checkbox
                      color="primary"
                      name="hidden"
                      checked={toBool(site.hidden)}
                    />
                  }
                  label="Hide Status View"
                />
              </FormGroup>
            </Grid>}
          </Grid>

          <Divider className={classes.divider} />

          {/* CAMERA */}
          <Grid container spacing={2}>
            {canView([1]) && <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Camera ID"
                variant="outlined"
                name="camera_id"
                type="number"
                value={safeNull(site.camera_id)}
                fullWidth
              />
            </Grid>}
            {canView([1,2,3]) && <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Camera Name"
                variant="outlined"
                name="camera_name"
                value={safeNull(site.camera_name)}
                fullWidth
              />
            </Grid>}
            {canView([1]) && <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Camera Model"
                variant="outlined"
                name="camera_model"
                value={safeNull(site.camera_model)}
                fullWidth
              />
            </Grid>}
            {canView([1]) && <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Software Version"
                variant="outlined"
                name="software_version"
                value={safeNull(site.software_version)}
                fullWidth
              />
            </Grid>}
          </Grid>

          <Divider className={classes.divider} />

          {/* MODES */}
          <FormGroup row>
            {canView([1,2,3]) && <FormControlLabel control={
                <Checkbox
                  color="primary"
                  name="deepsleep"
                  checked={toBool(site.deepsleep)}
                />
              }
              label="Deep Sleep Mode"
            />}
            {canView([1]) && <FormControlLabel control={
                <Checkbox
                  color="primary"
                  name="maintenance_mode"
                  checked={toBool(site.maintenance_mode)}
                />
              }
              label="Maintenance Mode"
            />}
            {canView([1]) && <FormControlLabel control={
                <Checkbox
                  color="primary"
                  name="continuous"
                  checked={toBool(site.continuous)}
                />
              }
              label="Continuous Mode"
            />}
          </FormGroup>

          <Divider className={classes.divider} />

          {/* MISC */}
          <Grid container spacing={2}>
            {canView([1,2,3]) && <Grid item xs={12}>
              <TextField
                label="Address"
                variant="outlined"
                name="address"
                value={safeNull(site.address)}
                rows={4}
                multiline
                fullWidth
              />
            </Grid>}

            {canView([1,2,3]) && <Grid item xs={12} sm={4}>
              <TextField
                label="Client Name"
                variant="outlined"
                name="client_contact"
                value={safeNull(site.client_contact)}
                fullWidth
              />
            </Grid>}
            {canView([1,2,3]) && <Grid item xs={12} sm={4}>
              <TextField
                label="Client Phone"
                variant="outlined"
                name="client_phone"
                value={safeNull(site.client_phone)}
                fullWidth
              />
            </Grid>}
            {canView([1,2,3]) && <Grid item xs={12} sm={4}>
              <TextField
                label="Client Email"
                variant="outlined"
                name="client_email"
                value={safeNull(site.client_email)}
                fullWidth
              />
            </Grid>}

            {canView([1,2,3]) && <Grid item xs={12} sm={4}>
              <TextField
                label="Manager Name"
                variant="outlined"
                name="manager_contact"
                value={safeNull(site.manager_contact)}
                fullWidth
              />
            </Grid>}
            {canView([1,2,3]) && <Grid item xs={12} sm={4}>
              <TextField
                label="Manager Phone"
                variant="outlined"
                name="manager_phone"
                value={safeNull(site.manager_phone)}
                fullWidth
              />
            </Grid>}
            {canView([1,2,3]) && <Grid item xs={12} sm={4}>
              <TextField
                label="Manager Email"
                variant="outlined"
                name="manager_email"
                value={safeNull(site.manager_email)}
                fullWidth
              />
            </Grid>}

            {canView([1]) && <Grid item xs={6}>
              <TextField
                label="Latitude"
                variant="outlined"
                name="loc_latitude"
                value={safeNull(site.loc_latitude)}
                fullWidth
              />
            </Grid>}
            {canView([1]) && <Grid item xs={6}>
              <TextField
                label="Longitude"
                variant="outlined"
                name="loc_longitude"
                value={safeNull(site.loc_longitude)}
                fullWidth
              />
            </Grid>}

            {canView([1]) && <Grid item xs={12}>
              <TextField
                label="Description"
                variant="outlined"
                name="description"
                value={safeNull(site.description)}
                rows={4}
                multiline
                fullWidth
              />
            </Grid>}
          </Grid>

          <Divider className={classes.divider} />

          {/* BILLING */}
          <Grid container spacing={2}>
            {canView([1]) && <Grid item xs={12} sm={6} md={3}>
              <KeyboardDatePicker
                label="Billing Start"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                value={site.start_date}
                onChange={val => props.handleDataChange(
                  'start_date',
                  val === null ? null : formatISO(val)
                )}
                fullWidth
                clearable
              />
            </Grid>}
            {canView([1]) && <Grid item xs={12} sm={6} md={3}>
              <KeyboardDatePicker
                label="Billing End"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                value={site.completion_date}
                onChange={val => props.handleDataChange(
                  'completion_date',
                  val === null ? null : formatISO(val)
                )}
                fullWidth
                clearable
              />
            </Grid>}
            {canView([1]) && <Grid item xs={12} sm={6} md={3}>
              <KeyboardDatePicker
                label="Billing Paused From"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                value={site.nonbilling_start}
                onChange={val => props.handleDataChange(
                  'nonbilling_start',
                  val === null ? null : formatISO(val)
                )}
                fullWidth
                clearable
              />
            </Grid>}
            {canView([1]) && <Grid item xs={12} sm={6} md={3}>
              <KeyboardDatePicker
                label="Billing Paused To"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                value={site.nonbilling_end}
                onChange={val => props.handleDataChange(
                  'nonbilling_end',
                  val === null ? null : formatISO(val)
                )}
                fullWidth
                clearable
              />
            </Grid>}
            {canView([1]) && <Grid item xs={12} md={6}>
              <TextField
                label="Billing Rate"
                variant="outlined"
                type="number"
                name="billing_rate"
                value={safeNull(site.billing_rate)}
                fullWidth
              />
            </Grid>}
          </Grid>

          {saving
            ? <CircularProgress className={classes.submitButton} />
            : <Button
                variant="outlined"
                type="submit"
                className={classes.submitButton}
              >
                Submit
              </Button>}
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default EditSiteDialog;