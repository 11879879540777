import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import EditUserForm from './EditUserForm';

class EditUserDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: ""
    }
  }

  handleChange = (key, val) => {
    if(key === "password") {
      this.setState({ password: val });
    } else {
      var data = { ...this.props.editUser };
      data[key] = val;
      this.props.setEditUser(data);
    }
  }

  render() {
    const { editUser, handleClose, editUserSubmit } = this.props;

    return (
      <Dialog
        open={editUser !== null}
        onClose={handleClose}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle>Edit User</DialogTitle>
  
        {editUser !== null && <DialogContent>
          <EditUserForm
            user={editUser}
            handleChange={this.handleChange}
            editUserSubmit={editUserSubmit}
          />
        </DialogContent>}
      </Dialog>
    );
  }
}

export default EditUserDialog;