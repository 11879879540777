import React from 'react';
import { BottomNavigation, BottomNavigationAction, makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExploreIcon from '@material-ui/icons/Explore';
import BusinessIcon from '@material-ui/icons/Business';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: '0',
    textAlign: 'center',
    zIndex: 1
  }
}))


const valMap = {
  "": 0,
  "home": 0,
  "sites": 1,
  "businesses": 2
}
const linkMap = {
  0: "",
  1: "/sites",
  2: "/businesses"
}

function getVal() {
  var url = new URL(window.location.href);
  var page = url.pathname.split("/")[1];
  return valMap[page];
};

function BottomNav() {
  const classes = useStyles();
  var history = useHistory();
  const account = useSelector(state => state.auth.account);

  return (
    <div className={classes.root}>
      <BottomNavigation
        value={getVal()}
        onChange={(event, newValue) => {
          history.push(linkMap[newValue]);
        }}
        showLabels
      >
        <BottomNavigationAction label="Home" icon={<HomeIcon />} />
        <BottomNavigationAction label="Sites" icon={<ExploreIcon />} />
        {account.level_id < 4 && <BottomNavigationAction label="Business" icon={<BusinessIcon />} />}
      </BottomNavigation>
    </div>
  );
}

export default BottomNav;