import React from 'react';
import GoogleMapReact from 'google-map-react';
import { makeStyles } from '@material-ui/styles';

import LocationIcon from './LocationIcon';

const useStyles = makeStyles(theme => ({
  map: {
    height: '40vh',
    width: '100%',
    marginTop: theme.spacing(2)
  }
}));

function ShowMap(props) {
  const classes = useStyles();
  const { site } = props;

  const position = {
    center: {
      lat: parseFloat(site.loc_latitude),
      lng: parseFloat(site.loc_longitude)
    },
    zoom: 12
  }

  var map = null;
  if(
    site.loc_latitude != null &&
    site.loc_longitude != null &&
    site.loc_latitude.length > 0 &&
    site.loc_longitude.length > 0
  ) {
    map = (
      <div className={classes.map}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDFUe7M41gqlkbQh6G4mRXxwl_dxPugjbU" }}
          center={position.center}
          zoom={position.zoom}
        >
          <LocationIcon
            lat={site.loc_latitude}
            lng={site.loc_longitude}
          />
        </GoogleMapReact>
      </div>
    );
  }
  return map;
}

function Map(props) {
  const { site } = props;

  const doShow = site.loc_latitude !== null && site.loc_longitude !== null;

  return (
    <div>
      {doShow && <ShowMap site={site} />}
    </div>
  );
}

export default Map;