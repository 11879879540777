import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, LinearProgress } from '@material-ui/core';
import Axios from 'axios';

import TopNav from '../TopNav';
import { setAppTheme } from '../../../actions';

// Pages
import Site from '../../Site';


class PublicView extends Component {
  componentDidMount() {
    Axios.get(`/sites/${this.props.match.params.siteID}/theme`)
      .then(res => this.props.setAppTheme(res.data.data))
      .catch(err => console.log(err));
  }

  render() {
    const { app, classes } = this.props;

    return (
      <>
        <TopNav />
        {app.loading && <LinearProgress />}

        <Container maxWidth="lg" className={classes.container}>
          <Site match={this.props.match} />
        </Container>
      </>
    );
  }
}


const mapStateToProps = state => ({
  app: state.app,
  auth: state.auth
});

const mapDispatchToProps = {
  setAppTheme
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicView);