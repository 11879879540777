import { parse } from "date-fns";

export default url => {
  const re1 = new RegExp(/^([0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.jpg)$/);
  const re2 = new RegExp(/^([0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}-[0-9]{2}-[0-9]{2}\.jpg)$/);
  const re3 = new RegExp(/^([0-9]{4}-[0-9]{2}-[0-9]{2}_[0-9]{2}-[0-9]{2}-[0-9]{2}\.jpg)$/);

  var date = new Date();
  if(re1.test(url)) {
    date = parse(url, "yyyy-MM-dd'T'HH:mm:ss'.jpg'", new Date());
  } else if(re2.test(url)) {
    date = parse(url, "yyyy-MM-dd'T'HH-mm-ss'.jpg'", new Date());
  } else if(re3.test(url)) {
    date = parse(url, "yyyy-MM-dd_HH-mm-ss'.jpg'", new Date());
  }

  return date;
}