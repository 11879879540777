import { parse, parseISO, isValid } from 'date-fns';

export default dateString => {
  try {
    var date;
    date = parseISO(dateString);
    if(isValid(date)) return date;

    date = parse(dateString, "EEE MMM d HH:mm:ss y", new Date());
    if(isValid(date)) return date;

    return new Date(dateString);
  } catch (error) {
    console.log("Failed to parse date.");

    return new Date();
  }
}