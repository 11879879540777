import React, { useRef } from 'react';
import { Paper, Divider, Checkbox, makeStyles, IconButton, Tooltip } from '@material-ui/core';
import { format } from 'date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';

import DeleteIcon from '@material-ui/icons/Delete';
import CompareIcon from '@material-ui/icons/Compare';
import parseUrlToDate from '../../../utils/parseUrlToDate';


const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    width: 'auto'
  },
  imageArea: {
    overflowY: 'scroll',
    height: 670
  },
  image: {
    marginTop: 5,
    width: "90%",
    borderRadius: '2%',
    cursor: 'pointer'
  },
  imageDate: {
    marginTop: 5,
    marginBottom: 0
  },
  fromDate: {
    width: '90%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  imageContainer: {
    position: 'relative'
  },
  checkbox: {
    position: 'absolute',
    right: 4,
    color: 'white'
  }
}));


function VerticalSelector(props) {
  const selectorRef = useRef();
  const classes = useStyles();

  const handleScroll = (e) => {
    if((e.target.scrollHeight - e.target.scrollTop) * 0.7 < e.target.clientHeight) {
      // Load more images
      props.getImages(false, false);
    }
  }

  const { images, selectedImages, url, previewImage, toggleSelectImage, fromDate, setFromDate, canView } = props;

  var lastDateString = null;

  return (
    <Paper className={classes.root}>
      <div>
        <KeyboardDatePicker
          value={fromDate}
          onChange={date => {
            selectorRef.current.scrollTop = 0;
            setFromDate(date);
          }}
          className={classes.fromDate}
          format="dd/MM/yyyy"
          inputVariant="outlined"
          label="From Date"
          fullWidth
        />
      </div>

      <Divider />

      <div className={classes.imageArea} onScroll={evt => handleScroll(evt)} ref={selectorRef}>
        {images.map((image, index) => {
          let useDateString = false;
          let date = parseUrlToDate(image.url);
          let dateString = format(date, "d/M/Y");
          if(dateString !== lastDateString) {
            useDateString = true;
            lastDateString = dateString;
          }
          
          return (
            <div key={index} className={classes.imageContainer}>
              {useDateString && <p className={classes.imageDate}>{dateString}</p>}
              <img
                className={classes.image}
                src={`${url}t_${image.url}`}
                alt={image.url}
                onClick={() => previewImage(index)}
              />
              <Checkbox
                checked={selectedImages.indexOf(image) > -1}
                color="default"
                className={classes.checkbox}
                onChange={() => toggleSelectImage(image)}
              />
            </div>
          );
        })}
      </div>

      <Divider />

      <div>
        <Tooltip title="Compare Images">
          <IconButton
            className={classes.settingButton}
            onClick={() => props.toggleCompareImages()}
          >
            <CompareIcon />
          </IconButton>
        </Tooltip>
        {canView([1,2,3]) && <Tooltip title="Delete Images">
          <IconButton
            onClick={props.deleteSelectedImages}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>}
      </div>
    </Paper>
  );
}

export default VerticalSelector;