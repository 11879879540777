import React, { useRef } from 'react';
import { Paper, Typography, Grid, Checkbox, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format } from 'date-fns';

import DeleteIcon from '@material-ui/icons/Delete';
import CompareIcon from '@material-ui/icons/Compare';
import parseUrlToDate from '../../../utils/parseUrlToDate';


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  },
  gallary: {
    textAlign: 'center',
    height: 'auto',
    overflowX: 'scroll',
    whiteSpace: 'nowrap',
    maxWidth: '100%'
  },
  options: {
    marginTop: theme.spacing(1)
  },
  imageContainer: {
    maxWidth: "22%",
    display: 'inline-block',
    position: 'relative'
  },
  image: {
    width: "90%",
    borderRadius: '4%',
    cursor: 'pointer',
    maxHeight: 80
  },
  imageDate: {
    marginTop: 5,
    marginBottom: 5,
    width: 0
  },
  checkbox: {
    [theme.breakpoints.down('xs')]: {
      marginTop: -5,
      right: 0
    },
    [theme.breakpoints.up('sm')]: {
      right: 4
    },
    position: 'absolute',
    color: 'white'
  }
}));

function HorizontalSelector(props) {
  const selectorRef = useRef();
  const classes = useStyles();
  
  const handleScroll = e => {
    if((e.target.scrollWidth - e.target.scrollLeft) * 0.7 < e.target.clientWidth) {
      // Load more images
      props.getImages(false, false);
    }
  }

  const { images, selectedImages, url, previewImage, toggleSelectImage, fromDate, setFromDate, canView } = props;

  let lastDateString = null;

  return (
    <Paper className={classes.root}>
      <div className={classes.gallary} onScroll={evt => handleScroll(evt)} ref={selectorRef}>
        {images.map((image, index) => {
          let useDateString = false;
          let date = parseUrlToDate(image.url);
          let dateString = format(date, "d/M/Y");
          if(dateString !== lastDateString) {
            useDateString = true;
            lastDateString = dateString;
          }
          
          return (
            <div className={classes.imageContainer} key={index}>
              {useDateString && <Typography className={classes.imageDate} variant="body2">{dateString}</Typography>}
              <img
                className={classes.image}
                src={`${url}t_${image.url}`}
                alt={image.url}
                onClick={() => previewImage(index)}
              />
              <Checkbox
                checked={selectedImages.indexOf(image) > -1}
                color="default"
                className={classes.checkbox}
                onChange={() => toggleSelectImage(image)}
              />
            </div>
          );
        })}
      </div>

      <div className={classes.options}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <KeyboardDatePicker
              value={fromDate}
              onChange={date => {
                selectorRef.current.scrollTop = 0;
                setFromDate(date);
              }}
              className={classes.fromDate}
              format="dd/MM/yyyy"
              inputVariant="outlined"
              label="From Date"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Tooltip title="Compare Images">
              <IconButton
                className={classes.settingButton}
                onClick={() => props.toggleCompareImages()}
              >
                <CompareIcon />
              </IconButton>
            </Tooltip>
            {canView([1,2,3]) && <Tooltip title="Delete Images">
              <IconButton
                onClick={props.deleteSelectedImages}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>}
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}

export default HorizontalSelector;