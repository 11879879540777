import React, { Component } from 'react';
import { getBusinesses } from '../../utils/getters';
import { setAppLoading, updateBusinesses } from '../../actions';
import { connect } from 'react-redux';
import BusinessesTable from './BusinessesTable';
import { Button, withStyles } from '@material-ui/core';
import AddBusiness from './Dialogs/AddBusiness';


const styles = theme => ({
  addButton: {
    marginBottom: theme.spacing(1)
  }
});


class Business extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddDialog: false
    }
  }

  componentDidMount() {
    this.props.setAppLoading(true);
    getBusinesses()
      .then(res => this.props.updateBusinesses(res.data.data))
      .catch(err => console.log(err))
      .finally(() => this.props.setAppLoading(false));
  }

  showAddDialog = (state=!this.state.showAddDialog) => {
    this.setState({ showAddDialog: state });
  }

  addBusiness = data => {
    var businesses = Object.values(this.props.businesses);
    businesses.push(data);
    this.props.updateBusinesses(businesses);
  }

  render() {
    const { showAddDialog } = this.state;
    const { classes } = this.props;
    const isAdmin = this.props.auth.account.level_id === 1;

    return (
      <>
        {isAdmin && (
          <AddBusiness
            open={showAddDialog}
            addBusiness={this.addBusiness}
            handleClose={() => this.showAddDialog(false)}
          />
        )}
        {isAdmin && <Button
          variant="outlined"
          className={classes.addButton}
          onClick={() => this.showAddDialog(true)}
        >
          Add Business
        </Button>}

        <BusinessesTable businesses={this.props.businesses} />
      </>
    );
  }
}


const mapStateToProps = state => ({
  businesses: state.businesses.businesses,
  auth: state.auth
});

const mapDispatchToProps = {
  setAppLoading,
  updateBusinesses
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Business));