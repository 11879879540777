import React, { Component } from 'react';
import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Axios from 'axios';
import { connect } from 'react-redux';
import { resetApp, resetAuth, resetSites, resetRecents, resetBusinesses } from './actions';
import Main from './modules/Main';
import { CssBaseline } from '@material-ui/core';


// Version
export const version = 1;

// Config Axios
Axios.defaults.baseURL = "https://api.getprecisionvision.com";
// Axios.defaults.baseURL = "http://localhost:4000";

// Styles
const styles = {
  '@global': {
    "*::-webkit-scrollbar": {
      height: 5,
      width: 10
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,0.1)'
    }
  }
};

// App
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      error: false
    }

    // Logout on authentication error
    Axios.interceptors.response.use(config => {
      return config;
    }, err => {
      if(err.response.status === 401) {
        this.logout();
      }
      return Promise.reject(err);
    });
  }
  
  componentDidMount() {
    const { app, auth } = this.props;

    // Set Axios Auth Header
    if(auth.header != null) {
      Axios.defaults.headers.common['Authorization'] = this.props.auth.header;
    }

    // Version Check
    if(app.version == null || app.version !== version) {
      this.logout();
    }

    // Ready
    this.setState({
      ready: true
    });
  }

  componentDidCatch(error, info) {
    this.setState({ error: true });
    this.logout();
  }

  theme = () => {
    const mode = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? 'dark' : 'light';
    const { theme } = this.props.app;
    return createMuiTheme({
      palette: {
        type: mode,
        primary: {
          main: theme.primary
        },
        secondary: {
          main: theme.secondary
        }
      }
    });
  }

  logout = () => {
    this.props.resetAuth();
    this.props.resetApp();
    this.props.resetSites();
    this.props.resetRecents();
    this.props.resetBusinesses();
  }

  render() {
    const { ready, error } = this.state;

    if(error) {
      return <h1>Something went wrong. Please reload the page.</h1>;
    } else if(ready) {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider theme={this.theme()}>
            <CssBaseline />
            <Main />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      );
    } else {
      return <h1>Loading...</h1>;
    }
  }
}


const mapStateToProps = state => {
  return {
    app: state.app,
    auth: state.auth
  }
}

const mapDispatchToProps = {
  resetApp,
  resetAuth,
  resetSites,
  resetRecents,
  resetBusinesses
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App));
