import React from 'react';
import { Table, Paper, TableHead, TableRow, TableCell, TableBody, IconButton, CircularProgress, makeStyles, Tooltip, Hidden } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import TableContainer from '@material-ui/core/TableContainer';
import GetAppIcon from '@material-ui/icons/GetApp';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  }
}));


function ActionIcons(props) {
  const { path, deleteExport } = props;

  switch (path) {
    case null:
      return (
        <CircularProgress />
      );
    
    case false:
      return (
        <Tooltip title="Failed">
          <IconButton>
            <ClearIcon />
          </IconButton>
        </Tooltip>
      );
  
    default:
      return (
        <>
          <Tooltip title="Download">
            <IconButton target="_blank" href={`https://timelapsefootage.s3-ap-southeast-2.amazonaws.com/${path}`}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={deleteExport}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      );
  }
}


function DownloadTableRow(props) {
  const { data, deleteExport } = props;

  return (
    <TableRow>
      <TableCell>{format(parseISO(data.from_date), "dd/MM/yyyy")}</TableCell>
      <TableCell>{format(parseISO(data.to_date), "dd/MM/yyyy")}</TableCell>
      <TableCell>{data.resolution == null ? "N/A" : data.resolution}</TableCell>
      <Hidden smDown>
        <TableCell>{data.framerate == null ? "N/A" : data.framerate}</TableCell>
      </Hidden>
      <TableCell>{data.format.toUpperCase()}</TableCell>
      <TableCell>
        <ActionIcons
          path={data.path}
          deleteExport={deleteExport}
        />
      </TableCell>
    </TableRow>
  );
}

function DownloadTable(props) {
  const classes = useStyles();
  const { downloads, deleteExport } = props;

  var downloadsArray = Object.entries(downloads);

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>From Date</TableCell>
            <TableCell>To Date</TableCell>
            <TableCell>Resolution</TableCell>
            <Hidden smDown>
              <TableCell>Framerate</TableCell>
            </Hidden>
            <TableCell>Format</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {downloadsArray.length > 0
            ? (
              downloadsArray.map(download => (
                <DownloadTableRow
                  key={download[0]}
                  data={download[1]}
                  deleteExport={() => deleteExport(download[0])}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>No files available to download.</TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DownloadTable;