import React, { Component } from 'react';
import { Dialog, DialogTitle, Grid, Button, CircularProgress, Hidden, TextField, MenuItem, DialogContent } from '@material-ui/core';
import Axios from 'axios';
import handleError from '../../../utils/handleError';


const initialState = {
  data: {
    business_name: "",
    business_address: "",
    business_city: "",
    business_state: "",
    business_zip: "",
    business_country: "NZL",
    contact_name: "",
    contact_phone: "",
    contact_email: "",
    industry: "",
    website: "",
    small_logo: "",
    user_id: 0,
    theme_primary: "#2196f3",
    theme_secondary: "#FFFFFF",
    theme_appbar: "primary"
  },
  saving: false
}

class AddBusiness extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  handleChange = (key, val) => {
    var data = { ...this.state.data };
    data[key] = val;
    this.setState({ data });
  }

  submit = evt => {
    evt.preventDefault();
    this.setState({ saving: true });

    Axios.post('/account/businesses', this.state.data)
      .then(res => {
        this.props.addBusiness(res.data.data);
        this.props.handleClose();
        this.setState(initialState);
      })
      .catch(err => {
        this.setState({ saving: false });
        alert(handleError(err));
      });
  }

  render() {
    const { data, saving } = this.state;
    const { open, handleClose } = this.props;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle>Add Business</DialogTitle>

        <DialogContent>
          <form onSubmit={this.submit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Name"
                  name="business_name"
                  value={data.business_name}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  variant="outlined"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <TextField
                  label="Address"
                  name="business_address"
                  value={data.business_address}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  variant="outlined"
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  label="City"
                  name="business_city"
                  value={data.business_city}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  label="State"
                  name="business_state"
                  value={data.business_state}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  label="Country"
                  name="business_country"
                  value={data.business_country}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  variant="outlined"
                  fullWidth
                  select
                >
                  <MenuItem value="AUS">Australia</MenuItem>
                  <MenuItem value="NZL">New Zealand</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  label="Postcode"
                  name="business_zip"
                  value={data.business_zip}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  label="Contact Name"
                  name="contact_name"
                  value={data.contact_name}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  label="Contact Phone"
                  name="contact_phone"
                  value={data.contact_phone}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  label="Contact Email"
                  name="contact_email"
                  value={data.contact_email}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  label="Industry"
                  name="industry"
                  value={data.industry}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  variant="outlined"
                  fullWidth
                  select
                >
                  <MenuItem value="Construction">Construction</MenuItem>
                  <MenuItem value="Landscaping">Landscaping</MenuItem>
                  <MenuItem value="Agriculture">Agriculture</MenuItem>
                  <MenuItem value="Horticulture">Horticulture</MenuItem>
                  <MenuItem value="Demolition">Demolition</MenuItem>
                  <MenuItem value="Civil earthworks">Civil Earthworks</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  label="Website"
                  name="website"
                  value={data.website}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              {/* Theme Config */}
              <Grid item xs={6} sm={4}>
                <TextField
                  label="Appbar Theme"
                  name="theme_appbar"
                  value={data.theme_appbar}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  variant="outlined"
                  select
                  required
                  fullWidth
                >
                  <MenuItem value="inherit">Auto</MenuItem>
                  <MenuItem value="primary">Primary</MenuItem>
                  <MenuItem value="secondary">Secondary</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  label="Primary Colour"
                  name="theme_primary"
                  value={data.theme_primary}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  variant="outlined"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  label="Secondary Colour"
                  name="theme_secondary"
                  value={data.theme_secondary}
                  onChange={evt => this.handleChange(evt.target.name, evt.target.value)}
                  variant="outlined"
                  required
                  fullWidth
                />
              </Grid>

              {/* Submit */}
              <Hidden xsDown><Grid item xs={false} sm={8} /></Hidden>
              <Grid item xs={6} sm={4}>
                {saving
                  ? <CircularProgress />
                  : <Button
                      variant="outlined"
                      type="submit"
                      fullWidth
                    >
                      Save
                    </Button>}
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}

export default AddBusiness