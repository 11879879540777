import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import parseUrlToDate from '../../../utils/parseUrlToDate';

const useStyles = makeStyles(theme => ({
  info: {
    margin: 0
  },
  image: {
    width: '100%',
    borderRadius: '1%'
  }
}))


function Image(props) {
  const { image } = props;
  const classes = useStyles();

  let date = parseUrlToDate(image.url);

  return (
    <div>
      <a href={props.url + image.url} target="_blank" rel="noopener noreferrer">
        <img
          alt={image.url}
          src={`${props.url}m_${image.url}`}
          className={classes.image}
          onError={evt => evt.target.src = `${props.url}${image.url}`}
        />
      </a>
      <Typography
        className={classes.info}
        variant="body1"
      >
        {`Captured: ${format(date, "d/M/Y h:mm:ss a")}`}
      </Typography>
    </div>
  );
}

export default Image;