export default err => {
  try {
    if("data" in err.response.data) {
      return err.response.data.data.join("\n")
    } else {
      return err.response.data.message;
    }
  } catch (error) {
    console.log(err);
    return "An error occurred.";
  }
}