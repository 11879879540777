import React from 'react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, makeStyles } from '@material-ui/core';
import BusinessRow from './BusinessRow';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 400
  }
}))

function BusinessesTable(props) {
  const { businesses } = props;
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Contact</TableCell>
            <TableCell>Phone</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(businesses).map(business => (
            <BusinessRow key={business.business_id} business={business} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BusinessesTable;