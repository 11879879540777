import React, { Component } from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { setAppLoading, updateSites } from '../../actions';
import { getSites } from '../../utils/getters';

import SitesOverview from './SitesOverview';
import Map from '../SharedComponents/UserSitesMap';


const styles = theme => ({
  recentsArea: {
    width: '100%',
    display: 'inline-block'
  }
});


class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapPosition: {
        center: {
          lat: -34,
          lng: 165
        },
        zoom: 4
      },
      sites: []
    }
  }

  componentDidMount() {
    this.props.setAppLoading(true);
    getSites({ small: true, latestImage: true, status: true })
      .then(res => this.props.updateSites(res.data.data))
      .catch(err => console.log(err))
      .finally(() => this.props.setAppLoading(false));
  }

  moveMap = (lat, lng, zoom=this.state.mapPosition.zoom) => {
    this.setState({
      mapPosition: {
        center: {
          lat: lat,
          lng: lng
        },
        zoom: zoom
      }
    });
  }

  render() {
    const { mapPosition } = this.state;
    const { sites } = this.props;

    return (
      <div>
        <Typography variant="h5" gutterBottom>Sites</Typography>
        <SitesOverview />

        <br/>

        <Typography variant="h5" gutterBottom>Map</Typography>
        <Map sites={sites} position={mapPosition} />
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    sites: state.sites.sites
  }
}

const mapDispatchToProps = {
  setAppLoading,
  updateSites
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Home));