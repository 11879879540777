import React from 'react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    transform: 'translate(-50%, -90%)',
    cursor: 'pointer',
    fontSize: '3em',
    color: 'black'
  }
}));

function LocationIcon(props) {
  const { site } = props;
  const classes = useStyles();
  const history = useHistory();

  return (
    <LocationOnIcon
      className={classes.icon}
      onClick={() => history.push(`/sites/${site.site_id}`)}
    />
  );
}

export default LocationIcon;