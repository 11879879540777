import React, { useState } from 'react';
import { TextField, Grid, Button, Typography, CircularProgress } from '@material-ui/core';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { updateAccount } from '../../actions';
import handleError from '../../utils/handleError';


function PasswordForm(props) {
  const dispatch = useDispatch();

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [saving, setSaving] = useState(false);

  const handleSubmit = evt => {
    evt.preventDefault();

    if(newPassword.length < 5) {
      alert("Password must be at least 5 characters long.");
    } else if(newPassword !== confirmNewPassword) {
      alert("Passwords must match.");
    } else if(newPassword.includes(":")) {
      alert("Password cannot contain a ':' character.");
    } else {
      setSaving(true);

      Axios.put('/account', {
        password: newPassword
      })
        .then(res => {
          dispatch(updateAccount({ password: newPassword }));
        })
        .catch(err => {
          alert(handleError(err));
        })
        .finally(() => {
          setSaving(false);
        });
    }
  }

  return (
    <Grid item sm={6}>
      <Typography variant="h6">Password</Typography>
      <form
        onSubmit={handleSubmit}
      >
        <TextField
          name="new-password"
          label="New Password"
          margin="normal"
          autoComplete="new-password"
          type="password"
          value={newPassword}
          onChange={evt => setNewPassword(evt.target.value)}
          fullWidth
          required
        />
        <TextField
          name="confirm-new-password"
          label="Confirm New Password"
          margin="normal"
          autoComplete="new-password"
          type="password"
          value={confirmNewPassword}
          onChange={evt => setConfirmNewPassword(evt.target.value)}
          fullWidth
          required
        />
        {saving 
          ? <CircularProgress />
          : <Button
            variant="outlined"
            type="submit"
            >
              Save
            </Button>}
      </form>
    </Grid>
  );
}

export default PasswordForm;