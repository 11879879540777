import React, { useState } from 'react';
import { Card, CardActionArea, makeStyles, Grid, CardContent, Typography, Tooltip } from '@material-ui/core';
import offlineIcon from '../../../offline-icon.svg';
import { useHistory } from 'react-router-dom';


import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';
import Battery20Icon from '@material-ui/icons/Battery20';
import Battery30Icon from '@material-ui/icons/Battery30';
import Battery50Icon from '@material-ui/icons/Battery50';
import Battery60Icon from '@material-ui/icons/Battery60';
import Battery80Icon from '@material-ui/icons/Battery80';
import Battery90Icon from '@material-ui/icons/Battery90';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import { green, grey, red, deepOrange, lightGreen } from '@material-ui/core/colors';


const useStyles = makeStyles(theme => ({
  mediaContainer: {
    [theme.breakpoints.down('xs')]: {
      height: '20vw'
    },
    [theme.breakpoints.up('sm')]: {
      height: '12vw'
    },
    [theme.breakpoints.up('lg')]: {
      height: 160
    },
    overflowY: 'hidden',
    textAlign: 'center'
  },
  mediaOnline: {
    width: '100%'
  },
  mediaOffline: {
    height: '100%',
    opacity: 0.1
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    position: 'relative'
  },
  batteryIcon: {
    [theme.breakpoints.down('md')]: {
      top: theme.spacing(1)
    },
    [theme.breakpoints.up('lg')]: {
      top: theme.spacing(2)
    },
    position: 'absolute',
    right: theme.spacing(1)
  }
}));


function Picture(props) {
  const { site } = props;
  const classes = useStyles();

  const [offline, setOffline] = useState(site.image === null);

  return (
    <div className={classes.mediaContainer}>
      <img
        alt="Latest"
        src={offline ? offlineIcon : `${site.url}t_${site.image.url}`}
        className={offline ? classes.mediaOffline : classes.mediaOnline}
        onError={evt => {
          setOffline(true);
        }}
      />
    </div>
  );
}


function SiteCard(props) {
  const { site } = props;
  const { status } = site;
  const classes = useStyles();
  const history = useHistory();

  var BatteryIcon = BatteryUnknownIcon;
  var batteryColor = grey[500];
  var battery = null;

  if(!(status === null || status === undefined)) {
    battery = parseFloat(status.charge);
    if(battery < 25) {
      BatteryIcon = Battery20Icon;
      batteryColor = red[500];
    } else if (battery < 40) {
      BatteryIcon = Battery30Icon;
      batteryColor = deepOrange[700];
    } else if (battery < 55) {
      BatteryIcon = Battery50Icon;
      batteryColor = deepOrange[400];
    } else if (battery < 75) {
      BatteryIcon = Battery60Icon;
      batteryColor = lightGreen[500];
    } else if (battery < 85) {
      BatteryIcon = Battery80Icon;
      batteryColor = lightGreen[500];
    } else if (battery < 95) {
      BatteryIcon = Battery90Icon;
      batteryColor = green[500];
    } else {
      BatteryIcon = BatteryFullIcon;
      batteryColor = green[500];
    }
  }

  return (
    <Grid item xs={4} sm={3}>
      <Card onClick={() => history.push(`sites/${site.site_id}`)}>
        <CardActionArea>
          <Picture site={site} />

          <CardContent className={classes.content}>
            <Typography variant="h6">
              {site.site_name}
            </Typography>
            <Tooltip title={battery === null ? "Unknown" : `${battery}%`} className={classes.batteryIcon}>
              <BatteryIcon style={{ color: batteryColor }} />
            </Tooltip>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  )
}

export default SiteCard;